import { BaseApi } from '.';
import { ICategory } from 'main/interfaces';
import { ApiBaseResponse, ApiWithPaginationResponse, BaseApiPostData, IBaseGetAllParams } from 'src/interfaces';

class CategoryApi extends BaseApi {
    constructor() {
        super('/category');
    }

    async getAll(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<ICategory>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<ICategory>>>(`?page=${params?.page ?? 1}`))?.data);
    }

    async getOne(id: string): Promise<ApiBaseResponse<ICategory> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ICategory>>('/' + id))?.data);
    }

    async create(data: BaseApiPostData): Promise<ApiBaseResponse<ICategory> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<ICategory>>('/', data))?.data);
    }

    async update(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ICategory> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<ICategory>>('/' + id, data))?.data);
    }

    async updateInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ICategory> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<ICategory>>('/' + id + '/info', data))?.data);
    }

    async createInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ICategory> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<ICategory>>('/' + id + '/info', data))?.data);
    }
}

export const categoryApi = new CategoryApi();
