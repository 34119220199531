import React from 'react';
import { FloatButton } from 'antd';
import { OrderTTN } from '../';
import { IOM_Order } from 'main/services/OrderManager';
import { TruckOutlined, FileTextOutlined } from '@ant-design/icons';

interface IOrderDetailButtons {
  order: IOM_Order;
  onChange: () => void;
}

export const OrderDetailButtons: React.FC<IOrderDetailButtons> = ({ order, onChange }) => {
  const handleChange = (): void => {
    onChange();
  };

  return (
    <FloatButton.Group shape="circle" target={'header'}>
      <OrderTTN order={order} onChange={handleChange}>
        <FloatButton badge={{ count: '!' }} icon={<TruckOutlined/>} type={'primary'}/>
      </OrderTTN>
      <FloatButton badge={{ count: 19 }} icon={<FileTextOutlined/>} type={'primary'}/>
    </FloatButton.Group>
  );
};
