import React from 'react';
import { TabsProps, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import {
    ProductSeoTab,
    ProductMainTab,
    ProductCategoryTab,
    ProductTagTab,
    ProductAttributeTab,
    ProductDiscountsTab,
    ProductRecommendsTab,
    ProductImagesTab,
    ProductDocumentsTab
} from '../';

export const ProductEditForm: React.FC = () => {
    const { id } = useParams();
    const items: TabsProps['items'] = id ? [
        {
            key: 'main',
            label: 'Основні',
            children: <ProductMainTab product_id={id}/>,
        },
        {
            key: 'seo',
            label: 'SEO',
            children: <ProductSeoTab product_id={id}/>,
            destroyInactiveTabPane: true,
        },
        {
            key: 'category',
            label: 'Категорії',
            children: <ProductCategoryTab product_id={id}/>,
        },
        {
            key: 'tags',
            label: 'Теги',
            children: <ProductTagTab product_id={id}/>,
        },
        {
            key: 'attributes',
            label: 'Атрибути',
            children: <ProductAttributeTab product_id={id}/>,
        },
        {
            key: 'sale_and_taxes',
            label: 'Знижки',
            children: <ProductDiscountsTab product_id={id}/>,
            destroyInactiveTabPane: true,
        },
        {
            key: 'recommends',
            label: 'Рекомендовані товари',
            children: <ProductRecommendsTab product_id={id}/>,
            destroyInactiveTabPane: true,
        },
        {
            key: 'images',
            label: 'Зображення',
            children: <ProductImagesTab product_id={id}/>,
        },
        {
            key: 'documents',
            label: 'Документи',
            children: <ProductDocumentsTab product_id={id}/>,
        },
    ] : [];

    return (
        <Tabs defaultActiveKey="1" items={items} />
    );
};
