import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { ProductEditForm } from '../components';

export const CatalogProductEditPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'Редагування продукту'}
                        subtitle={'Внесіть необхідні зміни в налаштування продукту'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href="/products">Продукти</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Редагування продукту
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            <ProductEditForm/>
        </>
    );
};
