
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { AttributeApi, ProductApi } from 'src/api';
import { IAttribute, IAttributeValue, IProductAttribute } from 'main/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { ProductAttributeForm, ProductAttributes } from '../';

interface IProductAttributeTabProps {
    product_id: string;
}

export const ProductAttributeTab: React.FC<IProductAttributeTabProps> = ({ product_id }) => {
    const [attributes, setAttributes] = useState<IAttribute[] | undefined>();
    const [productAttributes, setProductAttributes] = useState<IProductAttribute[] | undefined>();
    const [attributeValues, setAttributeValues] = useState<IAttributeValue[] | undefined>();

    const filteredAttributes = useMemo(() => {
        const addedAttributesIds = productAttributes?.map((attribute) => attribute.id_attribute);
        return attributes?.filter((attribute) => {
            return !addedAttributesIds?.includes(attribute.id);
        });
    }, [productAttributes, attributes]);

    const getProductAttributes = useCallback(() => {
        ProductApi.getAttributes(product_id).then((res) => {
            setProductAttributes(res?.data);
        });
    }, [product_id]);

    const handleAddAttribute = useCallback(async (attribute_id: number, value_id: number) => {
        const response = await ProductApi.addAttribute(Number(product_id), attribute_id, value_id);
        handleChangeStatus<IProductAttribute>(response);
        getProductAttributes();
        return response;
    }, [product_id, getProductAttributes]);

    const handleDeleteAttribute = useCallback(async (product_attribute_id: number) => {
        const response = await ProductApi.deleteAttribute(Number(product_id), product_attribute_id);
        handleChangeStatus<IProductAttribute>(response);
        getProductAttributes();
        return response;
    }, [product_id, getProductAttributes]);

    const handleCreateAttributeValue = useCallback(async (attribute_id: number, name: string) => {
       return await AttributeApi.addValue(attribute_id, { name });
    }, []);

    const handleGetAttributeValues = useCallback(async (attribute_id: number) => {
        const response = await AttributeApi.getValues(attribute_id);
        setAttributeValues(response?.data);
    }, []);

    useEffect(() => {
        if (!productAttributes) {
            getProductAttributes();
        }
    }, [productAttributes, getProductAttributes]);

    useEffect(() => {
        if (!attributes) {
            AttributeApi.getAll().then((res) => {
                setAttributes(res?.data?.rows);
            });
        }
    }, [attributes]);

    return productAttributes && attributes && filteredAttributes ? (
        <Row gutter={31}>
            <Col xs={24} sm={24} md={24} lg={9}>
                <Divider children={'Додати атрибут до продукту'} orientation="left" orientationMargin="0"/>
                <ProductAttributeForm attributes={filteredAttributes}
                                      attributeValues={attributeValues}
                                      onCreate={handleAddAttribute}
                                      onChangeAttribute={handleGetAttributeValues}
                                      onCreateValue={handleCreateAttributeValue}
                />
            </Col>
            <Col xs={24} sm={24} md={24} lg={9}>
                <Divider children={'Список доданих атрибутів'} orientation="left" orientationMargin="0"/>
                <ProductAttributes attributes={productAttributes} onDeleteAttribute={handleDeleteAttribute}/>
            </Col>
        </Row>
    ) : null;
};
