import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { CategoryEditForm } from '../components';
import { ROUTES } from 'src/constants';

export const CatalogCategoryEditPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'Редагування категорії'}
                        subtitle={'Внесіть необхідні зміни в налаштування категорії'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href={ROUTES.CATALOG_CATEGORIES}>Категорії</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Редагування категорії
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            <CategoryEditForm/>
        </>
    );
};
