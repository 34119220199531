import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { ProductApi } from 'src/api';
import { IProduct, IProductRecommend } from 'main/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { ProductRecommendsForm } from '../';

interface IProductRecommendsTabProps {
    product_id: string;
}

export const ProductRecommendsTab: React.FC<IProductRecommendsTabProps> = ({ product_id }) => {
    const [products, setProducts] = useState<IProduct[] | undefined>();
    const [productRecommends, setProductRecommends] = useState<IProductRecommend[] | undefined>();

    const getProducts = useCallback((value?: string) => {
        ProductApi.getAll({ name: value }).then((res) => {
            setProducts(res?.data?.rows);
        });
    }, []);

    const handleAddProduct = useCallback(async (tag_id: number) => {
        const response = await ProductApi.addRecommends(Number(product_id), tag_id);
        handleChangeStatus<IProductRecommend>(response);
        getProducts();
        return response;
    }, [product_id, getProducts]);

    const handleDeleteProduct = useCallback(async (tag_id: number) => {
        const response = await ProductApi.deleteRecommends(Number(product_id), tag_id);
        handleChangeStatus<IProductRecommend>(response);
        getProducts();
        return response;
    }, [product_id, getProducts]);

    const handleSearchProduct = useCallback((value: string): void => {
        getProducts(value);
    }, [getProducts]);

    useEffect(() => {
        if (!productRecommends) {
            ProductApi.getRecommends(product_id).then((res) => {
                setProductRecommends(res?.data);
            });
        }
    }, [product_id, productRecommends]);

    useEffect(() => {
        if (!products) {
            getProducts();
        }
    }, [products, getProducts]);

    return productRecommends && products ? (
        <>
            <Divider children={'Рекомендовані продукти'} orientation="left" orientationMargin="0"/>
            <ProductRecommendsForm products={products}
                            productRecommends={productRecommends}
                            onSearch={handleSearchProduct}
                            onCreate={handleAddProduct}
                            onDelete={handleDeleteProduct}
            />
        </>
    ) : null;
};
