import React from 'react';
import { withLanguageTabs } from 'src/hoc';
import { Col, Form, Input, Row } from 'antd';
import { ICategory } from 'main/interfaces';
import { handleFormChanges } from 'src/helpers';
import { FormChanges } from 'src/interfaces';

const { TextArea } = Input;

interface ICategoryMainInfoForm {
    language?: number;
    category: ICategory;
    onCreate: (changes: Record<string, string | number | boolean>, language: number) => void;
    onUpdate: (changes: Record<string, string | number | boolean>, language: number) => void;
}

const CategoryMainInfoForm: React.FC<ICategoryMainInfoForm> = ({ language, category, onCreate, onUpdate }) => {
    const categoryInfo = category?.info?.find((item) => item.id_language === language);
    const disabledField = !categoryInfo;
    const initialValues = {
        value: categoryInfo?.value,
        meta_title: categoryInfo?.meta_title,
        meta_keywords: categoryInfo?.meta_keywords,
        meta_description: categoryInfo?.meta_description,
    };

    const handleUpdates = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        if (language) {
            if (categoryInfo) {
                onUpdate(allValues, language);
            } else {
                onCreate(allValues, language);
            }
        }
    });

    return initialValues ? (
        <Form layout={'vertical'} initialValues={initialValues} onValuesChange={handleUpdates}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="value"
                               label="Назва"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="meta_title"
                               label="SEO: Meta-Title"
                               rules={[]}
                               hasFeedback
                    >
                        <Input disabled={disabledField}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={16}>
                    <Form.Item name="meta_keywords"
                               label="SEO: Meta-Keywords"
                               rules={[]}
                               hasFeedback
                    >
                        <Input disabled={disabledField}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={16}>
                    <Form.Item name="meta_description"
                               label="SEO: Meta-Description"
                               rules={[]}
                               hasFeedback
                    >
                        <TextArea rows={9} disabled={disabledField}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    ) : null;
};

export const categoryMainInfoForm = withLanguageTabs<ICategoryMainInfoForm>(CategoryMainInfoForm);
