import React, { SyntheticEvent, useState } from 'react';
import { IOrderProductLot } from 'main/interfaces';
import { BaseApiPostData } from 'src/interfaces';
import { InputNumber, Space, Typography } from 'antd';

interface IOrderEditProductsLotsCountProps {
    lot: IOrderProductLot;
    max?: number | null;
    onChange: (order_product_lot_id: number, order_product_lot: BaseApiPostData) => void;
}

export const OrderEditProductsLotsCount: React.FC<IOrderEditProductsLotsCountProps> = ({ lot, onChange, max }) => {
    const [count, setCount] = useState<number>(lot.count);

    const handleChangeCount = (value: number | null): void => {
        setCount(value ?? 0);
    };

    const handleChaneProduct = (event: SyntheticEvent<HTMLInputElement>): void => {
        onChange(lot.id, { id_warehouse: lot.id_warehouse, id_lot: lot.id_lot, count: event.currentTarget.value ?? 0 });
    };

    return (
        <Space direction={'vertical'} size={'small'}>
            <InputNumber value={count} onBlur={handleChaneProduct} onChange={handleChangeCount} addonAfter={'шт.'} />
            <Typography.Text type={'secondary'}>{'Доступно на складі: ' + max}</Typography.Text>
        </Space>
    );
};
