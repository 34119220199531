import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import { OrderEdit } from '../components';
import { ROUTES } from 'src/constants';

export const OrderEditPage: React.FC = () => {
    const { id } = useParams();

    return (
        <>
            <PageHeader title={'Редагування замовлення #' + id}
                        subtitle={'Детальний перегляд замовлення'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href={ROUTES.ORDERS}>Замовлення</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Редагування замовлення №{id}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            {id && <OrderEdit order_id={id}/>}
        </>
    );
};
