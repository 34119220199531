import { BaseApi } from '.';
import { IProductRelated } from 'main/interfaces';
import { ApiBaseResponse, BaseApiPostData, IBaseGetAllParams } from 'src/interfaces';

class SettingApi extends BaseApi {
  constructor() {
    super('/setting');
  }

  async getRelatedProducts(params: IBaseGetAllParams): Promise<ApiBaseResponse<IProductRelated[]> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<IProductRelated[]>>('/related'))?.data);
  }

  async updateRelatedProducts(related_product_id: number, data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
    return this.errorHandler((await this.PUT<ApiBaseResponse<Record<string, string>>>('/related/' + related_product_id, data))?.data);
  }

  async deleteRelatedProduct(related_product_id: number): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
    return this.errorHandler((await this.DELETE<ApiBaseResponse<Record<string, string>>>('/related/' + related_product_id))?.data);
  }

  async pushRelatedProduct(values: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
    return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/related', values))?.data);
  }
}

export const settingApi = new SettingApi();
