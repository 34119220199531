import React from 'react';
import { IWarehouseProductLot } from 'main/interfaces';
import { TableProps } from 'antd';
import { Table } from 'src/base-component';
import { Formatter } from 'src/helpers';

interface IWarehouseLotsProductsProps {
  products: IWarehouseProductLot[];
}

export const WarehouseLotsProducts: React.FC<IWarehouseLotsProductsProps> = ({ products }) => {
  const columns: TableProps<IWarehouseProductLot>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Назва лоту',
      dataIndex: 'lot',
      key: 'lot',
    },
    {
      title: 'К-сть (упаковка)',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'К-сть (штука)',
      dataIndex: 'count_retail',
      key: 'count_retail',
    },
    {
      title: 'Термін придатності',
      dataIndex: 'date_to',
      key: 'date_to',
      render: (_, record) => Formatter.toDate(record.date_to ?? ''),
    },
  ];

  return (
    <Table columns={columns} data={products} loading={false}/>
  );
};
