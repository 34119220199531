import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb } from 'antd';
import { RelatedProductsGrid } from '../components';

export const RelatedProductsPage: React.FC = () => {
  return (
    <>
      <PageHeader title={'Рекомендовані товари'}
                  subtitle={'Управління рекомендованими товарами на головній'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Рекомендовані товари
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
      />
      <RelatedProductsGrid/>
    </>
  );
};
