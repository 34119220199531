import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, Space } from 'antd';
import { IOM_Info } from 'main/services/OrderManager';
import { CustomerApi, DirectoriesApi, OrderApi } from 'src/api';
import {
    OrderEditCustomer,
    OrderEditMain,
    OrderEditPayment,
    OrderEditDeliveries,
    OrderEditProducts,
} from '../';
import { ApiBaseResponse, BaseApiPostData, ORDER_STATUSES } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { ICustomer, IDelivery, IDiscount, IPayment } from 'main/interfaces';

const Panel = Collapse.Panel;

interface IOrderEditProps {
    order_id: string;
}

export const OrderEdit: React.FC<IOrderEditProps> = ({ order_id }) => {
    const [order, setOrder] = useState<IOM_Info>();
    const [customers, setCustomers] = useState<ICustomer[]>();
    const [deliveries, setDeliveries] = useState<IDelivery[]>();
    const [payments, setPayments] = useState<IPayment[]>();
    const [loading, setLoading] = useState(true);

    const getOrder = useCallback((): void => {
        setLoading(true);
        OrderApi.getOne(order_id).then((res) => {
            setOrder(res?.data);
            setLoading(false);
        });
    }, [order_id]);

    const handleChangeOrder = useCallback((values: BaseApiPostData): void => {
        setLoading(true);
        OrderApi.update(Number(order_id), values).then((res) => {
            handleChangeStatus<IOM_Info>(res);
            getOrder();
            setLoading(false);
        });
    }, [order_id, getOrder]);

    const handleAddLot = useCallback(async (order_product_id: number, values: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
        setLoading(true);
        const res = await OrderApi.addProductLot(Number(order_product_id), values);
        handleChangeStatus<Record<string, string>>(res);
        getOrder();
        setLoading(false);
        return res;
    }, [getOrder]);

    const handleAddProduct = useCallback(async (values: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
        setLoading(true);
        const res = await OrderApi.addProduct(Number(order_id), values);
        handleChangeStatus<Record<string, string>>(res);
        getOrder();
        setLoading(false);
        return res;
    }, [order_id, getOrder]);

    const handleChangeDiscount = useCallback(async (values: Record<string, IDiscount>): Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
        setLoading(true);
        const res = await OrderApi.addDiscount(Number(order_id), values);
        handleChangeStatus<Record<string, string>>(res);
        getOrder();
        setLoading(false);
        return res;
    }, [order_id, getOrder]);

    const handleDeleteProduct = useCallback((order_product_id: number): void => {
        setLoading(true);
        OrderApi.deleteProduct(Number(order_id), Number(order_product_id)).then((res) => {
            handleChangeStatus<boolean>(res);
            getOrder();
            setLoading(false);
        });
    }, [order_id, getOrder]);

    const handleDeleteLot = useCallback((order_product_lot_id: number): void => {
        setLoading(true);
        OrderApi.deleteProductLot(Number(order_product_lot_id)).then((res) => {
            handleChangeStatus<boolean>(res);
            getOrder();
            setLoading(false);
        });
    }, [getOrder]);

    const handleUpdateProduct = useCallback((product: BaseApiPostData): void => {
        setLoading(true);
        OrderApi.updateProduct(Number(order_id), Number(product?.id), product).then((res) => {
            handleChangeStatus<Record<string, string>>(res);
            getOrder();
            setLoading(false);
        });
    }, [order_id, getOrder]);

    const handleUpdateLot = useCallback((order_product_lot_id: number, lot: BaseApiPostData): void => {
        setLoading(true);
        OrderApi.updateProductLot(Number(order_product_lot_id), lot).then((res) => {
            handleChangeStatus<Record<string, string>>(res);
            getOrder();
            setLoading(false);
        });
    }, [getOrder]);

    useEffect(() => {
        if (!order) {
            getOrder();
        }
    }, [order, getOrder]);

    useEffect(() => {
        if (!customers) {
            setLoading(true);
            CustomerApi.getAll({}).then((res) => {
                setCustomers(res?.data?.rows);
                setLoading(false);
            });
        }
    }, [customers]);

    useEffect(() => {
        if (!deliveries) {
            setLoading(true);
            DirectoriesApi.getDeliveries().then((res) => {
                setDeliveries(res?.data);
                setLoading(false);
            });
        }
    }, [deliveries]);

    useEffect(() => {
        if (!payments) {
            setLoading(true);
            DirectoriesApi.getPayments().then((res) => {
                setPayments(res?.data);
                setLoading(false);
            });
        }
    }, [payments]);

    if (!order || !customers || !deliveries || !payments) return null;

    return (
        <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
            <Collapse defaultActiveKey={['1']} destroyInactivePanel accordion>
                <Panel header="Основні" key="1">
                    <OrderEditMain order={order} customers={customers} payments={payments} deliveries={deliveries} onChange={handleChangeOrder} loading={loading} />
                </Panel>
                <Panel header="Дані замовника" key="2">
                    <OrderEditCustomer order={order} onChange={handleChangeOrder} loading={loading} />
                </Panel>
                <Panel header="Доставка" key="3">
                    <OrderEditDeliveries order={order} onChange={handleChangeOrder} loading={loading} />
                </Panel>
                <Panel header="Дані платника (компанії)" key="4">
                    <OrderEditPayment order={order} onChange={handleChangeOrder} loading={loading} />
                </Panel>
            </Collapse>
            {order?.products && (
                <OrderEditProducts order_products={order.products}
                                   onAddLot={handleAddLot}
                                   onChangeLot={handleUpdateLot}
                                   onDeleteLot={handleDeleteLot}
                                   discounts={order.discounts}
                                   disabled={![ORDER_STATUSES.NEW, ORDER_STATUSES.AWAITING_PAYMENT].includes(order.id_status)}
                                   onChangeDiscount={handleChangeDiscount}
                                   onAddProduct={handleAddProduct}
                                   onDeleteProduct={handleDeleteProduct}
                                   onChangeProduct={handleUpdateProduct}
                />
            )}
        </Space>
        // <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
        //     <Card title="Основні">
        //         <OrderEditMain order={order} customers={customers} payments={payments} deliveries={deliveries} onChange={handleChangeOrder} loading={loading} />
        //     </Card>
        //     <Space/>
        //     <Card title="Дані замовника">
        //         <OrderEditCustomer order={order} onChange={handleChangeOrder} loading={loading} />
        //     </Card>
        //     <Card title="Доставка">
        //         <OrderEditDeliveries order={order} onChange={handleChangeOrder} loading={loading} />
        //     </Card>
        //     <Card title="Дані платника (компанії)">
        //         <OrderEditPayment order={order} onChange={handleChangeOrder} loading={loading} />
        //     </Card>
        // </Space>
    );
};
