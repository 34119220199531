import React from 'react';
import { OrderApi } from 'src/api';
import { Button, Popover, Space, TableProps } from 'antd';
import { Formatter } from 'src/helpers';
import { Table } from 'src/base-component/Table/_Table';
import { IOM_Order } from 'main/services/OrderManager';
import { OrderStatus, OrderStatusForm } from 'src/apps/order/components';
import { FolderViewOutlined } from '@ant-design/icons';
import { ROUTES } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { IBaseGetAllParams } from '@/interfaces';

interface ICustomerOrdersProps {
  customer_id: string;
}

export const CustomerOrders: React.FC<ICustomerOrdersProps> = ({ customer_id }) => {
  const navigate = useNavigate();

  const handleOpen = (id: number): () => void => {
    return () => navigate(ROUTES.ORDERS + '/views/' + id);
  };

  const getColumns = (handleGetData: (params?: Record<string, string | number | boolean | undefined>) => void): TableProps<IOM_Order>['columns'] => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Сума',
      dataIndex: 'total',
      key: 'total',
      render: (_, record) => Formatter.toMoney(record.total ?? 0)
    },
    {
      title: 'Статус',
      dataIndex: 'status_name',
      key: 'status_name',
      render: (_, record) => (
        <Popover content={<OrderStatusForm order_id={record.id} allowed_statuses={record.allowedStatuses ?? []} onChange={handleGetData} />} trigger="click">
          <Button type={'link'}>
            <OrderStatus id={record.id_status} name={record.status_name ?? ''} />
          </Button>
        </Popover>
      )
    },
    {
      title: 'Передзамовлення',
      dataIndex: 'is_preorder',
      key: 'is_preorder',
      render: (_, record) => record.is_preorder ? 'Передзамовлення' : '-',
    },
    {
      title: 'Партнерське',
      dataIndex: 'is_partner',
      key: 'is_partner',
      render: (_, record) => record.is_partner ? 'Партнерське' : '-',
    },
    {
      title: 'Замовник',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'Платник',
      dataIndex: 'payment_client',
      key: 'payment_client',
      render: (_, record) => record?.payment_company ?? record?.payment_client,
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => Formatter.toDate(record.createdAt),
    },
    {
      title: 'Дії',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={handleOpen(record.id)}>
            <FolderViewOutlined/>
          </Button>
        </Space>
      ),
    },
  ];

  const onGetDatas = (id_customer: string) => {
    return (params: IBaseGetAllParams) => OrderApi.getAll.bind(OrderApi)({ ...params, id_customer });
  };

  return (
    <Table getColumns={getColumns} getData={onGetDatas(customer_id)} />
  );
};
