import { BaseApi } from '.';
import { ApiBaseResponse } from 'src/interfaces';
import { IDelivery, IPayment, ISpecialty, IStatus } from 'main/interfaces';

class DirectoriesApi extends BaseApi {
    constructor() {
        super('/directory');
    }

    async getStatuses(): Promise<ApiBaseResponse<IStatus[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IStatus[]>>('/status'))?.data);
    }

    async getPayments(): Promise<ApiBaseResponse<IPayment[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IPayment[]>>('/payment'))?.data);
    }

    async getDeliveries(): Promise<ApiBaseResponse<IDelivery[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IDelivery[]>>('/delivery'))?.data);
    }

    async getSpecialty(): Promise<ApiBaseResponse<ISpecialty[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ISpecialty[]>>('/specialty'))?.data);
    }
}

export const directoriesApi = new DirectoriesApi();
