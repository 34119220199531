import React, { useMemo, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { IProductTag, ITag } from 'main/interfaces';

interface IProductTagFormProps {
    tags: ITag[];
    productTags: IProductTag[];
    onCreate: (tag_id: number) => void;
    onDelete: (tag_id: number) => void;
}

export const ProductTagForm: React.FC<IProductTagFormProps> = ({ tags, productTags, onCreate, onDelete }) => {
    const tagOptions = tags.map((tag) => ({
        value: tag.id,
        label: tag?.info?.[0]
            ? tag.info?.[0].value
            : ''
    }));
    const defaultValues = useMemo(() => productTags.map((option) => Number(option.id_tag)), [productTags]);
    const [selected, setSelected] = useState<number[]>(defaultValues);

    const handleChange = (value: number[]): void => {
        selected.forEach((tag) => {
            if (!value.includes(tag)) {
                onDelete(tag);
            }
        });
        value.forEach((tag) => {
            if (!selected.includes(tag)) {
                onCreate(tag);
            }
        });
        setSelected(value);
    };

    return (
        <Form layout={'vertical'}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={10}>
                    <Form.Item label="Теги товара">
                        <Select
                            mode="multiple"
                            placeholder=""
                            variant="filled"
                            value={selected}
                            onChange={handleChange}
                            style={{ flex: 1 }}
                            options={tagOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
