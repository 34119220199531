import { CatalogApp } from './catalog';
import { OrderApp } from './order';
import { CustomerApp } from './customer';
import { WarehouseApp } from './warehouse';
import { SettingsApp } from './settings';
import { EconomicalApp } from './economical';

export { MainApp, AuthContext } from './main';
export { CatalogApp } from './catalog';

export const APPLICATIONS = [
    CatalogApp,
    OrderApp,
    SettingsApp,
    EconomicalApp,
    CustomerApp,
    WarehouseApp,
];
