import React from 'react';
import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { handleFormChanges } from 'src/helpers';
import { FormChanges, PRODUCT_FIELDS } from 'src/interfaces';
import { IManufacturer, IProduct } from 'main/interfaces';

const { Option } = Select;

interface IProductGeneralInfoFormProps {
    product?: IProduct;
    onUpdate: (changedValues: FormChanges) => void;
    manufacturers: IManufacturer[];
}

export const ProductGeneralInfoForm: React.FC<IProductGeneralInfoFormProps> = ({ product, onUpdate, manufacturers }) => {
    const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        onUpdate(allValues);
    });

    return (
        <Form layout={'vertical'} onValuesChange={handleChange} initialValues={product}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={PRODUCT_FIELDS.ALIAS}
                               label="Аліас"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={PRODUCT_FIELDS.MODEL}
                               label="Модель"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                        name={PRODUCT_FIELDS.ID_MANUFACTURER}
                        label="Виробник"
                        hasFeedback
                        rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                    >
                        <Select placeholder="Оберіть варіант із списку">
                            {manufacturers?.map((manufacturer) => (<Option value={manufacturer.id} key={manufacturer.id}>{manufacturer.name}</Option>))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}/>
            </Row>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={4}>
                    <Form.Item name={PRODUCT_FIELDS.PRICE}
                               label="Ціна"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <InputNumber addonAfter={'€'}/>
                    </Form.Item>
                    <Form.Item name={PRODUCT_FIELDS.PRICE_ONE}
                               label="Ціна за одиницю"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <InputNumber addonAfter={'€'}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={4}>
                    <Form.Item name={PRODUCT_FIELDS.IN_PACK}
                               label="Кількість в упаковці"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <InputNumber addonAfter={'шт'}/>
                    </Form.Item>
                    <Form.Item name={PRODUCT_FIELDS.VAT}
                               label="Податок"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <InputNumber addonAfter={'%'}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={4}>
                    <Form.Item name={PRODUCT_FIELDS.IS_PREORDER}
                               label="Для передзамовлення"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item name={PRODUCT_FIELDS.PARTNER_ACCESS}
                               label="Для партнерської програми"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={4}>
                    <Form.Item name={PRODUCT_FIELDS.IS_ACTIVE}
                               label="Активний"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item name={PRODUCT_FIELDS.IS_NEW}
                               label="Новинка"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
