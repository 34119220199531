import React from 'react';
import {
  Button,
  Form,
  Input,
  Space
} from 'antd';
import { ICustomerCompany } from 'main/interfaces';
import { ApiBaseResponse, BaseApiPostData } from '@/interfaces';

interface ICustomerRegisterCompanyProps {
  onSuccess: (values: BaseApiPostData) => Promise<ApiBaseResponse<ICustomerCompany> | undefined>;
}

export const CustomerRegisterCompany: React.FC<ICustomerRegisterCompanyProps> = ({ onSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleFinish = (values: Record<string, string | boolean | number>): void => {
    setLoading(true);
    onSuccess(values).then((res) => {
      if (res?.status) form.resetFields();
      setLoading(false);
    });
  };

  return (
    <Form
      name="validate_other"
      onFinish={handleFinish}
      form={form}
      labelCol={{ span: 6 }}
      disabled={loading}
      wrapperCol={{ span: 14 }}
      initialValues={{
        show_menu: false,
      }}
      style={{ maxWidth: 900 }}
    >
      <Form.Item name={'name'}
                 label="Назва компанії "
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'code'}
                 label="Код компанії"
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'pdv_code'}
                 label="Код платника ПДВ"
                 rules={[
                   { required: true, message: 'Поле повинно бути заповнене' },
                 ]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'address'}
                 label="Адреса"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Space>
          <Button type="primary" htmlType="submit">
            Створити
          </Button>
          <Button htmlType="reset">Скинути</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
