type Values = [number, number, string] | [number, number, string, string];

interface IMapNumbers {
    [k: string]: Values
}

const mapNumbers: IMapNumbers = {
    0: [2, 1, 'нуль'],
    1: [0, 2, 'один', 'одна'],
    2: [1, 2, 'два', 'дві'],
    3: [1, 1, 'три'],
    4: [1, 1, 'чотири'],
    5: [2, 1, 'п\'ять'],
    6: [2, 1, 'шість'],
    7: [2, 1, 'сім'],
    8: [2, 1, 'вісім'],
    9: [2, 1, 'дев\'ять'],
    10: [2, 1, 'десять'],
    11: [2, 1, 'одинадцять'],
    12: [2, 1, 'дванадцять'],
    13: [2, 1, 'тринадцять'],
    14: [2, 1, 'чотирнадцять'],
    15: [2, 1, 'п\'ятнадцять'],
    16: [2, 1, 'шістнадцять'],
    17: [2, 1, 'сімнадцять'],
    18: [2, 1, 'вісімнадцять'],
    19: [2, 1, 'дев\'ятнадцять'],
    20: [2, 1, 'двадцять'],
    30: [2, 1, 'тридцять'],
    40: [2, 1, 'сорок'],
    50: [2, 1, 'п\'ятдесят'],
    60: [2, 1, 'шістдесят'],
    70: [2, 1, 'сімдесят'],
    80: [2, 1, 'вісімдесят'],
    90: [2, 1, 'дев\'яносто'],
    100: [2, 1, 'сто'],
    200: [2, 1, 'двісті'],
    300: [2, 1, 'триста'],
    400: [2, 1, 'чотириста'],
    500: [2, 1, 'п\'ятсот'],
    600: [2, 1, 'шістсот'],
    700: [2, 1, 'сімсот'],
    800: [2, 1, 'вісімсот'],
    900: [2, 1, 'дев\'ятсот']
};

type PluralAmount = [string, string, string];

const mapOrders: PluralAmount[] = [
    ['гривня', 'гривні', 'гривень'],
    ['тисяча', 'тисячі', 'тисяч'],
];

const objKop: PluralAmount = ['копійка', 'копійки', 'копійок'];

function Value(dVal: number): string | undefined {
    const xVal: Values = mapNumbers[dVal];

    if (xVal[1] === 1) {
        return xVal[2];
    } else {
        return xVal[3];
    }
}

function From0To999(
    fValue: number,
    oObjDesc: PluralAmount,
    fnAddDesc: (kop: string) => void,
    fnAddNum?: (val: string | undefined) => void
): void {
    let nCurrState = 2;

    if (Math.floor(fValue / 100) > 0) {
        const fCurr: number = Math.floor(fValue / 100) * 100;
        fnAddNum && fnAddNum(Value(fCurr));
        nCurrState = mapNumbers[fCurr][0];
        fValue -= fCurr;
    }

    if (fValue < 20) {
        if (Math.floor(fValue) > 0) {
            fnAddNum && fnAddNum(Value(fValue));
            nCurrState = mapNumbers[fValue][0];
        }
    } else {
        const fCurr: number = Math.floor(fValue / 10) * 10;
        fnAddNum && fnAddNum(Value(fCurr));
        nCurrState = mapNumbers[fCurr][0];
        fValue -= fCurr;

        if (Math.floor(fValue) > 0) {
            fnAddNum && fnAddNum(Value(fValue));
            nCurrState = mapNumbers[fValue][0];
        }
    }

    fnAddDesc(oObjDesc[nCurrState]);
}

export function amountToWords(fAmount: number): string {
    let fInt: number = Math.floor(fAmount + 0.005);
    const fDec: number = Math.floor(((fAmount - fInt) * 100) + 0.5);

    const arrRet: string[] = [];
    const arrThousands: number[] = [];

    for (; fInt > 0.9999; fInt /= 1000) {
        arrThousands.push(Math.floor(fInt % 1000));
    }

    if (arrThousands.length === 0) {
        arrThousands.push(0);
    }

    function PushToRes(strVal: string | undefined): void {
        arrRet.push(strVal as string);
    }

    for (let iSouth = arrThousands.length - 1; iSouth >= 0; --iSouth) {
        if (arrThousands[iSouth] === 0) {
            continue;
        }
        From0To999(arrThousands[iSouth], mapOrders[iSouth], PushToRes, PushToRes);
    }

    if (arrThousands[0] === 0) {
// Handle zero amount
        if (arrThousands.length === 1) {
            PushToRes(Value(0));
        }

        const nCurrState = 2;
        PushToRes(mapOrders[0][nCurrState]);
    }

    if (arrRet.length > 0) {
// Capitalize first letter
        arrRet[0] = arrRet[0][0].toUpperCase() + arrRet[0].substr(1);
    }

    arrRet.push(fDec.toString().padStart(2, '0'));

    From0To999(fDec, objKop, PushToRes);

    return arrRet.join(' ');
}
