import './Logo.scss';

import React from 'react';
import LogoImage from 'src/assets/images/logo.png';
export const Logo: React.FC = () => {
    return (
        <a href={'/'} className={'mb-logo'}>
            <img src={LogoImage} alt="LOGO"/>
        </a>
    );
};
