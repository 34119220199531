import { BaseApi } from '.';
import { ILanguage } from 'main/interfaces';
import { ApiBaseResponse, ApiWithPaginationResponse } from 'src/interfaces';

class LanguageApi extends BaseApi {
    constructor() {
        super('/language');
    }

    async getAll(): Promise<ApiBaseResponse<ApiWithPaginationResponse<ILanguage>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<ILanguage>>>())?.data);
    }
}

export const languageApi = new LanguageApi();
