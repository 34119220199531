import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { CategoryApi, ProductApi } from 'src/api';
import { ICategory, IProductCategory } from 'main/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { ProductCategoryForm } from '../';

interface IProductCategoryTabProps {
    product_id: string;
}

export const ProductCategoryTab: React.FC<IProductCategoryTabProps> = ({ product_id }) => {
    const [categories, setCategories] = useState<ICategory[] | undefined>();
    const [productCategories, setProductCategories] = useState<IProductCategory[] | undefined>();

    const handleAddCategory = useCallback(async (category_id: number) => {
        const response = await ProductApi.addCategory(Number(product_id), category_id);
        handleChangeStatus<IProductCategory>(response);
        return response;
    }, [product_id]);

    const handleDeleteCategory = useCallback(async (category_id: number) => {
        const response = await ProductApi.deleteCategory(Number(product_id), category_id);
        handleChangeStatus<IProductCategory>(response);
        return response;
    }, [product_id]);

    useEffect(() => {
        if (!productCategories) {
            ProductApi.getCategories(product_id).then((res) => {
                setProductCategories(res?.data);
            });
        }
    }, [product_id, productCategories]);

    useEffect(() => {
        if (!categories) {
            CategoryApi.getAll({}).then((res) => {
                setCategories(res?.data?.rows);
            });
        }
    }, [categories]);

    return productCategories && categories ? (
        <>
            <Divider children={'Категорії продукту'} orientation="left" orientationMargin="0"/>
            <ProductCategoryForm categories={categories}
                                 productCategories={productCategories}
                                 onCreate={handleAddCategory}
                                 onDelete={handleDeleteCategory}
            />
        </>
    ) : null;
};
