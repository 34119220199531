import React, { useMemo } from 'react';
import { Card, Col, Row, Switch, Typography } from 'antd';
import { IGetOrderDiscounts } from 'main/services/OrderManager/OrderDiscounts';
import { Formatter } from 'src/helpers';
import { IDiscount } from 'main/interfaces';
import { ApiBaseResponse } from 'src/interfaces';

interface IOrderDiscountsProps {
  discounts: IGetOrderDiscounts;
  onChangeDiscount: (discount: Record<string, IDiscount>) => Promise<ApiBaseResponse<Record<string, string>> | undefined>
}

interface IOrderDiscountItem {
  id: number;
  amount: number | undefined;
  name: string | undefined;
  discount: IDiscount;
  is_active: boolean;
}

const _mapper = (is_active: boolean) => (discount: IDiscount) => {
  return {
    id: discount.id,
    amount: discount.total_sum,
    name: discount.name ?? (discount.info?.name ?? ''),
    discount: discount,
    is_active,
  };
};

export const OrderDiscounts: React.FC<IOrderDiscountsProps> = ({ discounts, onChangeDiscount }) => {
  const allDiscounts: IOrderDiscountItem[] = useMemo(() => [
    ...discounts.active_discounts.map(_mapper(true)),
    ...discounts.allowed_discounts.map(_mapper(false)),
  ].sort((a, b) => b.id - a.id), [discounts]);

  const handleActive = (discount: IDiscount): () => void => {
    return (): void => {
      onChangeDiscount({ discount });
    };
  };

  return (
    <Row>
      <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Card style={{ width: 569 }}>
          {allDiscounts.map(discount => (
            <Row key={discount.id} gutter={15} style={{ marginBottom: '15px' }}>
              <Col span={3}>
                <Switch value={discount.is_active} onChange={handleActive(discount.discount)}/>
              </Col>
              <Col span={14}>
                <Typography.Text type={'secondary'}>{discount.name}</Typography.Text>
              </Col>
              <Col span={7}>
                {Formatter.toMoney(discount.amount ?? 0, true)} грн
              </Col>
            </Row>
          ))}
        </Card>
      </Col>
    </Row>
  );
};
