import React from 'react';
import { IOM_Info } from 'main/services/OrderManager';
import { BaseApiPostData, FormChanges } from 'src/interfaces';
import { handleFormChanges } from 'src/helpers';
import { Col, Form, Input, InputNumber, Row } from 'antd';

interface IOrderEditPaymentProps {
    order: IOM_Info;
    loading: boolean;
    onChange: (values: BaseApiPostData) => void;
}

export const OrderEditPayment: React.FC<IOrderEditPaymentProps> = ({ order, onChange, loading }) => {
    const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        onChange(allValues);
    });

    return (
        <Form layout={'vertical'} onValuesChange={handleChange} initialValues={order} disabled={loading}>
            <Row gutter={30}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'payment_company'}
                               label="Назва компанії "
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'payment_company_address'}
                               label="Адреса компанії"
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'payment_company_code'}
                               label="Код ЄДРПОУ"
                               hasFeedback
                    >
                        <InputNumber style={{ width: '100%' }}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'payment_company_code_vat'}
                               label="Податковий номер"
                               hasFeedback
                    >
                        <InputNumber style={{ width: '100%' }}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
