import React from 'react';
import { withLanguageTabs } from 'src/hoc';
import { Col, Form, Input, Row } from 'antd';
import { ITag } from 'main/interfaces';
import { handleFormChanges } from 'src/helpers';
import { FormChanges } from 'src/interfaces';

interface ITagMainInfoForm {
    language?: number;
    tag: ITag;
    onCreate: (changes: Record<string, string | number | boolean>, language: number) => void;
    onUpdate: (changes: Record<string, string | number | boolean>, language: number) => void;
}

const TagMainInfoForm: React.FC<ITagMainInfoForm> = ({ language, tag, onCreate, onUpdate }) => {
    const tagInfo = tag?.info?.find((item) => item.id_language === language);
    const initialValues = {
        value: tagInfo?.value
    };

    const handleUpdates = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        if (language) {
            if (tagInfo) {
                onUpdate(allValues, language);
            } else {
                onCreate(allValues, language);
            }
        }
    });

    return initialValues ? (
        <Form layout={'vertical'} initialValues={initialValues} onValuesChange={handleUpdates}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="value"
                               label="Назва"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    ) : null;
};

export const tagMainInfoForm = withLanguageTabs<ITagMainInfoForm>(TagMainInfoForm);
