import { IEntryPoint, IRouteItem } from 'src/interfaces';
import { ROUTES } from 'src/constants';
import { OrderDetailPage, OrderEditPage, OrdersPage } from './pages';

const routeList: IRouteItem[] = [
    {
        path: ROUTES.ORDERS,
        exact: true,
        element: OrdersPage,
    },
    {
        path: ROUTES.ORDERS_VIEWS,
        exact: true,
        element: OrderDetailPage,
    },
    {
        path: ROUTES.ORDERS_EDIT,
        exact: true,
        element: OrderEditPage,
    },
];

export const OrderApp: IEntryPoint = {
    route: routeList,
};
