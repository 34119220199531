import './assets/css/index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { reportWebVitals } from 'src/reportWebVitals';
import { MainApp } from 'src/apps';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <MainApp/>
    </React.StrictMode>
);

reportWebVitals();
