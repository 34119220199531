import React from 'react';
import { IProductAttribute } from 'main/interfaces';
import { Button, List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IProductAttributesProps {
    attributes: IProductAttribute[];
    onDeleteAttribute: (product_attribute_id: number) => void;
}

export const ProductAttributes: React.FC<IProductAttributesProps> = ({ attributes, onDeleteAttribute }) => {
    const handleDelete = (product_attribute_id: number): void => {
        onDeleteAttribute(product_attribute_id);
    };

    return (
        <List
            bordered
            dataSource={attributes}
            renderItem={(item) => {
                const actions = [
                    <Button onClick={() => handleDelete(item.id)} key={item.id}>
                        <DeleteOutlined/>
                    </Button>
                ];
                return (
                    <List.Item actions={actions} style={{ display: 'flex' }} key={item.id}>
                        {item.attribute_name?.value ?? '-'}: {item.attribute_value_name?.value ?? '-'}
                    </List.Item>
                );
            }}
        />
    );
};
