import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { CategoryGeneralInfoForm, CategoryMainInfoForm } from '..';
import { CategoryApi } from 'src/api';
import { ICategory } from 'main/interfaces';
import { useParams } from 'react-router-dom';
import { ApiBaseResponse, FormChanges } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';

export const CategoryEditForm: React.FC = () => {
    const { id } = useParams();
    const [category, setCategory] = React.useState<ICategory | undefined>();
    const [categories, setCategories] = useState<ICategory[] | undefined>();

    const getCategory = useCallback((): void => {
        if (id) {
            CategoryApi.getOne(id).then((res) => {
                setCategory(res?.data);
            });
        }
    }, [id]);

    const handleUpdateCategoryInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<ICategory> | undefined> => {
        const response = await CategoryApi.updateInfo(Number(id), { ...changedValues, id_language: language });
        handleChangeStatus<ICategory>(response);
        return response;
    }, [id]);

    const handleCreateCategoryInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<ICategory> | undefined> => {
        const response = await CategoryApi.createInfo(Number(id), { ...changedValues, id_language: language });
        handleChangeStatus<ICategory>(response);
        getCategory();
        return response;
    }, [getCategory, id]);

    const handleUpdateCategory = useCallback(async (changedValues: FormChanges): Promise<ApiBaseResponse<ICategory> | undefined> => {
        const response = await CategoryApi.update(Number(id), changedValues);
        handleChangeStatus<ICategory>(response);
        return response;
    }, [id]);

    useEffect(() => {
        if (!category) {
            getCategory();
        }
    }, [id, category, getCategory]);

    useEffect(() => {
        if (!categories) {
            CategoryApi.getAll({}).then((res) => {
                setCategories(res?.data?.rows);
            });
        }
    }, [categories]);

    return category && categories ? (
        <>
            <Divider children={'Назва та опис'} orientation="left" orientationMargin="0"/>
            <CategoryMainInfoForm category={category} onCreate={handleCreateCategoryInfo} onUpdate={handleUpdateCategoryInfo}/>
            <Divider children={'Основна інформація'} orientation="left" orientationMargin="0"/>
            <CategoryGeneralInfoForm categories={categories} category={category} onUpdate={handleUpdateCategory}/>
        </>
    ) : null;
};
