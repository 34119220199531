import React from 'react';
import { IProduct } from 'main/interfaces';
import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { ApiBaseResponse, BaseApiPostData } from 'src/interfaces';

interface IRelatedProductsPushProps {
  products: IProduct[];
  onSearch: (value: string) => void;
  onAdd: (related_product: BaseApiPostData) => Promise<ApiBaseResponse<Record<string, string>> | undefined>;
}

export const RelatedProductsPush: React.FC<IRelatedProductsPushProps> = ({ products, onSearch, onAdd }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);

  const productOptions = products.map((product) => ({
    value: product.id,
    label: product?.info?.[0]
      ? product.model + ' | ' + product.info?.[0].name
      : ''
  }));

  const handleSearch = (value: string):void => {
    onSearch(value);
  };

  const handleFinish = (values: Record<string, string | boolean | number>): void => {
    setLoading(true);
    onAdd(values).then((res) => {
      if (res?.status) form.resetFields();
      setLoading(false);
    });
  };

  return (
    <Form
      name="validate_other"
      onFinish={handleFinish}
      form={form}
      layout={'horizontal'}
      disabled={loading}
      initialValues={{
        count: 1
      }}
    >
      <Row gutter={15}>
        <Col span={13}>
          <Form.Item label="Товар"
                     name={'id_product'}
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Select
              showSearch
              placeholder=""
              filterOption={(value, option) => option?.label.includes(value) ?? false}
              onSearch={handleSearch}
              options={productOptions}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'sort'}
                     label="Сортування"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <PlusCircleFilled/>
              Додати
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
