import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Table } from 'src/base-component';
import { Space, TableProps } from 'antd';
import { WarehouseApi } from 'src/api';
import { IWarehouseAction } from 'main/interfaces';
import { Formatter } from 'src/helpers';
import { WarehouseActionsProducts } from './WarehouseActionsProducts';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { ROUTES } from 'src/constants';

export enum WAREHOUSE_ACTIONS {
  RECEIVING = 'RECEIVING',
  WRITE_OFF = 'WRITE_OFF',
  ADD_RESERVED = 'ADD_RESERVED',
  REMOVE_RESERVED = 'REMOVE_RESERVED',
  ADD_PARTNER = 'ADD_PARTNER',
}

export const WarehouseActions: React.FC = () => {
  const [data, setData] = useState<IWarehouseAction[]>([]);
  const [loading, setLoading] = useState(true);
  const warehouseActions = data.map((item) => ({ ...item, key: item.id }));
  // const navigate = useNavigate();

  const getActions = useCallback((page?: number): void => {
    setLoading(true);
    WarehouseApi.getActions(page).then((res) => {
      setData(res?.data?.rows ?? []);
      setLoading(false);
    });
  }, []);

  // const handleEdit = (id: number): () => void => {
  //   return () => navigate(ROUTES.WAREHOUSES + '/actions/' + id);
  // };

  const getLabel = (type: WAREHOUSE_ACTIONS): ReactNode => {
    switch (type) {
      case WAREHOUSE_ACTIONS.ADD_RESERVED:
        return <Space style={{ color: 'green' }}><ArrowUpOutlined/> Зарезервовано</Space>;
      case WAREHOUSE_ACTIONS.REMOVE_RESERVED:
        return <Space style={{ color: 'red' }} ><ArrowDownOutlined/> Знято з резерву</Space>;
      case WAREHOUSE_ACTIONS.RECEIVING:
        return <Space style={{ color: 'green' }} ><ArrowUpOutlined/> Надходження</Space>;
      case WAREHOUSE_ACTIONS.WRITE_OFF:
        return <Space style={{ color: 'red' }} ><ArrowDownOutlined/> Списання</Space>;
      case WAREHOUSE_ACTIONS.ADD_PARTNER:
        return <Space style={{ color: 'green' }} ><ArrowUpOutlined/> Нарахування партнеру товарів</Space>;
      default:
        return type;
    }
  };

  const columns: TableProps<IWarehouseAction>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => getLabel(record.type),
    },
    {
      title: 'Коментар',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: '№ Замовлення',
      dataIndex: 'id_order',
      key: 'id_order',
      render: (_, record) => <a href={ROUTES.ORDERS + '/views/' + record.id_order}>Замовлення №{record.id_order}</a>
    },
    {
      title: 'Менеджер',
      key: 'user_name',
      render: (_, record) => record?.user?.info ? record.user.info[0]?.surname + ' ' + record.user.info[0]?.name : '',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => Formatter.toDate(record?.createdAt ?? ''),
    },
  ];

  const handleExpandable = (record: IWarehouseAction): ReactNode => {
    return (
      <WarehouseActionsProducts warehouse_action_id={record.id}/>
    );
  };

  useEffect(() => {
    getActions();
  }, [getActions]);

  return (
    // @ts-ignore
    <Table columns={columns} data={warehouseActions} onExpandable={handleExpandable} loading={loading} />
  );
};
