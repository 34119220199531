import React from 'react';
import { ThemeConfig, Layout, Auth } from './components';
import { BrowserRouter } from 'react-router-dom';

export const MainApp: React.FC = () => {
    return (
        <BrowserRouter>
            <ThemeConfig>
                <Auth>
                    <Layout/>
                </Auth>
            </ThemeConfig>
        </BrowserRouter>
    );
};
