import React from 'react';
import { Input, Button, Col, Form, Row } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { OrderApi } from 'src/api';
import { handleChangeStatus } from 'src/helpers';

interface IOrderTTNFormProps {
  order_id: number;
  onChange: () => void;
}

export const OrderTTNForm: React.FC<IOrderTTNFormProps> = ({ order_id, onChange }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleFinish = (values: Record<string, string | boolean | number>): void => {
    setLoading(true);
    OrderApi.updateTTN(Number(order_id), values).then((res) => {
      handleChangeStatus<Record<string, string>>(res);
      onChange();
      setLoading(false);
    });
  };

  return (
    <Form
      name="validate_other"
      onFinish={handleFinish}
      form={form}
      layout={'horizontal'}
      disabled={loading}
      initialValues={{
        count: 1
      }}
    >
      <Row gutter={5}>
        <Col span={19}>
          <Form.Item name={'ttn'}
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <PlusCircleFilled/>
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
