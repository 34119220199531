import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseApiPostData } from 'src/interfaces';
import { notification } from 'antd';
import { config } from 'src/config';

const HOST = config.API_URL;

export class BaseApi {
    private readonly baseUrl: string;
    private readonly endpointUrl: string;
    private readonly axiosConfig: AxiosRequestConfig;

    constructor(url: string) {
        this.baseUrl = HOST + '/api';
        this.endpointUrl = this.baseUrl + url;
        this.axiosConfig = { validateStatus: () => true, withCredentials: true };
    }

    errorHandler<T>(response: T & { status: number, message: string }): T | undefined {
        if ((response?.status) !== 1) {
            notification.error({
                message: 'Неуспіщне виконання операції',
                description: response?.message,
            });
            return;
        }
        return response;
    }

    get apiUrl(): string {
        return this.baseUrl;
    }

    async GET<T>(url?: string): Promise<AxiosResponse<T>> {
        return await axios.get(this.endpointUrl + (url ?? ''), this.axiosConfig).catch((error) => error.response.data);
    }

    async POST<T>(url?: string, data?: BaseApiPostData): Promise<AxiosResponse<T>> {
        return await axios.post(this.endpointUrl + (url ?? ''), data, this.axiosConfig).catch((error) => error.response.data);
    }

    async PUT<T>(url?: string, data?: BaseApiPostData): Promise<AxiosResponse<T>> {
        return await axios.put(this.endpointUrl + (url ?? ''), data, this.axiosConfig).catch((error) => error.response.data);
    }

    async DELETE<T>(url?: string): Promise<AxiosResponse<T>> {
        return await axios.delete(this.endpointUrl + (url ?? ''), this.axiosConfig).catch((error) => error.response.data);
    }
}
