import './Layout.scss';

import React, { useCallback, useState } from 'react';
import { Layout as AntLayout } from 'antd';
import { Header, Menu, Sider, Content, Logo, Router } from '../../components';

export const Layout: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const handleCollapse = useCallback((): void => {
        setCollapsed(!collapsed);
    }, [collapsed, setCollapsed]);

    return (
        <AntLayout className={'mb-main-layout'}>
            <Sider collapsed={collapsed}>
                <Logo/>
                <Menu/>
            </Sider>
            <AntLayout>
                <Header collapsed={collapsed} onCollapsed={handleCollapse} />
                <Content>
                    <Router/>
                </Content>
            </AntLayout>
        </AntLayout>
    );
};
