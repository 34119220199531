import React from 'react';
import { BaseApiPostData, FormChanges } from 'src/interfaces';
import { handleFormChanges } from 'src/helpers';
import { Col, Form, Input, Row, Switch } from 'antd';
import { ICustomer } from 'main/interfaces';

interface ICustomerEditMainProps {
  customer: ICustomer;
  loading: boolean;
  onChange: (values: BaseApiPostData) => void;
}

export const CustomerEditMain: React.FC<ICustomerEditMainProps> = ({ customer, loading, onChange }) => {
  const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
    onChange(allValues);
  });

  const initialsValues = {
    name: customer?.info?.name,
    surname: customer?.info?.surname,
    middle_name: customer?.info?.middle_name,
    email: customer?.info?.email,
    phone: customer?.info?.phone,
    active: customer.partner,
    partner: customer.partner,
  };

  return (
    <Form layout={'vertical'} onValuesChange={handleChange} initialValues={initialsValues} disabled={loading}>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'name'}
                     label="Імʼя"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'surname'}
                     label="Прізвище"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'middle_name'}
                     label="По батькові"
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'email'}
                     label="Email"
                     rules={[
                       { required: true, message: 'Поле повинно бути заповнене' },
                       { required: true, type: 'email', message: 'Введіть коректний email', }
                     ]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'phone'}
                     label="Телефон"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}/>
      </Row>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'active'}
                     label="Активний"
                     hasFeedback
          >
            <Switch/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'partner'}
                     label="Партнер"
                     hasFeedback
          >
            <Switch/>
          </Form.Item>
        </Col>
      </Row>
      {/*<Row gutter={30}>*/}
      {/*  <Col xs={24} sm={24} md={24} lg={8}>*/}
      {/*    <Form.Item name={'id_payment'}*/}
      {/*               label="Тип оплати"*/}
      {/*               hasFeedback*/}
      {/*    >*/}
      {/*      <Select placeholder="Оберіть варіант із списку">*/}
      {/*        {payments?.map((payment) => (*/}
      {/*          <Option value={payment.id} key={payment.id}>*/}
      {/*            {payment?.info?.[0]?.value}*/}
      {/*          </Option>*/}
      {/*        ))}*/}
      {/*      </Select>*/}
      {/*    </Form.Item>*/}
      {/*  </Col>*/}
      {/*  <Col xs={24} sm={24} md={24} lg={8}>*/}
      {/*    <Form.Item name={'is_partner'}*/}
      {/*               label="Партнерське"*/}
      {/*               hasFeedback*/}
      {/*    >*/}
      {/*      <Switch />*/}
      {/*    </Form.Item>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*<Row gutter={30}>*/}
      {/*  <Col xs={24} sm={24} md={24} lg={8}>*/}
      {/*    <Form.Item name={'id_customer'}*/}
      {/*               label="Клієнт"*/}
      {/*               hasFeedback*/}
      {/*    >*/}
      {/*      <Select placeholder="Оберіть варіант із списку">*/}
      {/*        {customers?.map((customer) => (*/}
      {/*          <Option value={customer.id} key={customer.id}>*/}
      {/*            {customer?.info?.name}*/}
      {/*          </Option>*/}
      {/*        ))}*/}
      {/*      </Select>*/}
      {/*    </Form.Item>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Form>
  );
};
