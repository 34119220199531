import { IEntryPoint, IRouteItem } from 'src/interfaces';
import { ROUTES } from 'src/constants';
import { WarehouseActionsPage, WarehouseAddActionPage, WarehouseEditPage, WarehousesPage } from './pages';

const routeList: IRouteItem[] = [
  {
    path: ROUTES.WAREHOUSES,
    exact: true,
    element: WarehousesPage,
  },
  {
    path: ROUTES.WAREHOUSES_EDIT,
    exact: true,
    element: WarehouseEditPage,
  },
  {
    path: ROUTES.WAREHOUSES_ACTIONS,
    exact: true,
    element: WarehouseActionsPage,
  },
  {
    path: ROUTES.WAREHOUSES_ACTIONS_ADD,
    exact: true,
    element: WarehouseAddActionPage,
  },
];

export const WarehouseApp: IEntryPoint = {
  route: routeList,
};
