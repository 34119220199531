import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'src/base-component';
import { Button, Space, TableProps } from 'antd';
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CustomerApi } from 'src/api';
import { ROUTES } from 'src/constants';
import { usePagination } from 'src/hook';
import { Formatter } from 'src/helpers';
import { ICustomer } from 'main/interfaces';
import { IBaseGetAllParams } from 'src/interfaces';

export const CustomerGrid: React.FC = () => {
  const [data, setData] = useState<ICustomer[]>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { pagination, handlePagination } = usePagination<ICustomer>();
  const paginationParams = useMemo(() => ({ page: pagination?.current ?? 1 }), [pagination]);

  const getCustomers = useCallback((params: IBaseGetAllParams): void => {
    setLoading(true);
    CustomerApi.getAll({ page: params?.page }).then((res) => {
      setData(res?.data?.rows ?? []);
      handlePagination(res, getCustomers, Number(params?.page));
      setLoading(false);
    });
  }, [handlePagination]);

  const handleEdit = (id: number): () => void => {
    return () => navigate(ROUTES.CUSTOMERS + '/edit/' + id);
  };

  const columns: TableProps<ICustomer>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ПІП',
      // dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.info ? `${record?.info?.surname} ${record?.info?.name} ${record?.info?.middle_name ?? ''}` : '',
    },
    {
      title: 'Email',
      // dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.info?.email ?? '-'
    },
    {
      title: 'Телефон',
      // dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.info?.phone ?? '-'
    },
    {
      title: 'Партнер',
      dataIndex: 'partner',
      key: 'partner',
      render: (_, record) => record?.partner ? <CheckCircleFilled style={{ fontSize: '19px', color: 'lightgreen' }}/> : ''
    },
    {
      title: 'Активний',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => record?.active ? <CheckCircleFilled style={{ fontSize: '19px', color: 'lightgreen' }}/> : ''
    },
    {
      title: 'Дата реєстрації',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => Formatter.toDate(record?.createdAt ?? ''),
    },
    {
      title: 'Дії',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={handleEdit(record.id)}>
            <EditOutlined/>
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!data) {
      getCustomers(paginationParams);
    }
  }, [getCustomers, paginationParams, data]);

  return (
    <Table columns={columns} data={data ?? []} loading={loading} pagination={pagination}/>
  );
};
