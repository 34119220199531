import React from 'react';
import { BaseApiPostData, FormChanges } from 'src/interfaces';
import { handleFormChanges } from 'src/helpers';
import { Col, Form, Input, Row } from 'antd';
import { ICustomerCompany } from 'main/interfaces';

interface ICustomerEditCompanyProps {
  company_info: ICustomerCompany;
  loading: boolean;
  onChange: (values: BaseApiPostData) => void;
}

export const CustomerEditCompany: React.FC<ICustomerEditCompanyProps> = ({ company_info, loading, onChange }) => {
  const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
    onChange(allValues);
  });

  return (
    <Form layout={'vertical'} onValuesChange={handleChange} initialValues={company_info} disabled={loading}>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'name'}
                     label="Назва компанії "
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'code'}
                     label="Код компанії"
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'pdv_code'}
                     label="Код платника ПДВ"
                     rules={[
                       { required: true, message: 'Поле повинно бути заповнене' },
                     ]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={16}>
          <Form.Item name={'address'}
                     label="Адреса"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
