export enum PRODUCT_FIELDS {
    MODEL = 'model',
    ALIAS = 'alias',
    ID_MANUFACTURER = 'id_manufacturer',
    PRICE = 'price',
    PRICE_ONE = 'price_one',
    IN_PACK = 'in_pack',
    VAT = 'vat',
    PARTNER_ACCESS = 'partner_access',
    IS_NEW = 'is_new',
    IS_PREORDER = 'is_preorder',
    IS_ACTIVE = 'is_active',
}
