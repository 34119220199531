import React, { SyntheticEvent, useState } from 'react';
import { InputNumber } from 'antd';
import { BaseApiPostData } from 'src/interfaces';
import { IProductRelated } from 'main/interfaces';

interface IRelatedProductsGridEditSortProps {
  related_product: IProductRelated;
  onChange: (related_product: BaseApiPostData) => void;
}

export const RelatedProductsGridEditSort: React.FC<IRelatedProductsGridEditSortProps> = ({ related_product, onChange }) => {
  const [sort, setSort] = useState<number>(related_product.sort);

  const handleChangeCount = (value: number | null): void => {
    setSort(value ?? 0);
  };

  const handleChaneProduct = (event: SyntheticEvent<HTMLInputElement>): void => {
    onChange({ id_product: related_product.id_product, sort: event.currentTarget.value ?? 0 });
  };

  return <InputNumber value={sort} onBlur={handleChaneProduct} onChange={handleChangeCount} />;
};
