import React from 'react';
import { TabsProps, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import {
    AttributeMainTab, AttributeInfoTab,
} from '../';

export const AttributeEditForm: React.FC = () => {
    const { id } = useParams();
    const items: TabsProps['items'] = id ? [
        {
            key: 'main',
            label: 'Основні налаштування',
            children: <AttributeMainTab attribute_id={id}/>,
        },
        {
            key: 'values',
            label: 'Значення',
            children: <AttributeInfoTab attribute_id={id}/>,
        },
    ] : [];

    return (
        <Tabs defaultActiveKey="1" items={items} />
    );
};
