import { BaseApi } from '.';
import { IAttribute, IAttributeInfo, IAttributeValue, IAttributeValueInfo } from 'main/interfaces';
import { ApiBaseResponse, ApiWithPaginationResponse, BaseApiPostData } from 'src/interfaces';

class AttributeApi extends BaseApi {
    constructor() {
        super('/attribute');
    }

    async getAll(): Promise<ApiBaseResponse<ApiWithPaginationResponse<IAttribute>> | undefined> {
        return (await this.GET<ApiBaseResponse<ApiWithPaginationResponse<IAttribute>> | undefined>())?.data;
    }

    async getOne(id: string): Promise<ApiBaseResponse<IAttribute> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IAttribute>>('/' + id))?.data);
    }

    async create(data: BaseApiPostData): Promise<ApiBaseResponse<IAttribute> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IAttribute>>('/', data))?.data);
    }

    async update(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IAttribute> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<IAttribute>>('/' + id, data))?.data);
    }

    async getInfo(id: string): Promise<ApiBaseResponse<IAttributeInfo[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IAttributeInfo[]>>('/' + id + '/info'))?.data);
    }

    async updateInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IAttributeInfo> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<IAttributeInfo>>('/' + id + '/info', data))?.data);
    }

    async createInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IAttributeInfo> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IAttributeInfo>>('/' + id + '/info', data))?.data);
    }

    async getValues(attribute_id: number): Promise<ApiBaseResponse<IAttributeValue[]> | undefined> {
        return (await this.GET<ApiBaseResponse<IAttributeValue[]> | undefined>('/' + attribute_id + '/value'))?.data;
    }

    async addValue(attribute_id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IAttributeValue> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IAttributeValue>>('/' + attribute_id + '/value', data))?.data);
    }

    async deleteValue(attribute_value_id: number): Promise<ApiBaseResponse<IAttributeValue> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<IAttributeValue>>('/value/' + attribute_value_id))?.data);
    }

    async updateValueInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IAttributeValueInfo> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<IAttributeValueInfo>>('/value/' + id + '/info', data))?.data);
    }

    async createValueInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IAttributeValueInfo> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IAttributeValueInfo>>('/value/' + id + '/info', data))?.data);
    }
}

export const attributeApi = new AttributeApi();
