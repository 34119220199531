export enum ORDER_STATUSES {
    NEW = 1,
    AWAITING_PAYMENT = 2,
    PAID = 3,
    COMPLETED = 4,
    IN_THE_WAY = 5,
    FINISHED = 6,
    WAITING = 7,
    CANCELED = 8,
    RETURNED = 9,
    COMPLAINT = 10,
    RETURN_TO_THE_WAREHOUSE = 11,
}
