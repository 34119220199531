import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { TagAddForm } from '../components';
import { ROUTES } from 'src/constants';

export const CatalogTagAddPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'Створення тегу'}
                        subtitle={'Заповніть форму щоб створити новий тег'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href={ROUTES.CATALOG_TAG}>Теги</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Створення тегу
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            <TagAddForm/>
        </>
    );
};
