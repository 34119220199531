import React, { useCallback, useEffect } from 'react';
import { Divider } from 'antd';
import { TagMainInfoForm } from '..';
import { TagApi } from 'src/api';
import { ITag } from 'main/interfaces';
import { useParams } from 'react-router-dom';
import { ApiBaseResponse, FormChanges } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';

export const TagEditForm: React.FC = () => {
    const { id } = useParams();
    const [tag, setTag] = React.useState<ITag | undefined>();

    const getTag = useCallback((): void => {
        if (id) {
            TagApi.getOne(id).then((res) => {
                setTag(res?.data);
            });
        }
    }, [id]);

    const handleUpdateTagInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<ITag> | undefined> => {
        const response = await TagApi.updateInfo(Number(id), { ...changedValues, id_language: language });
        handleChangeStatus<ITag>(response);
        return response;
    }, [id]);

    const handleCreateTagInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<ITag> | undefined> => {
        const response = await TagApi.createInfo(Number(id), { ...changedValues, id_language: language });
        handleChangeStatus<ITag>(response);
        getTag();
        return response;
    }, [getTag, id]);

    useEffect(() => {
        if (!tag) {
            getTag();
        }
    }, [getTag, id, tag]);

    return tag ? (
        <>
            <Divider children={'Назва'} orientation="left" orientationMargin="0"/>
            <TagMainInfoForm tag={tag} onCreate={handleCreateTagInfo} onUpdate={handleUpdateTagInfo} />
        </>
    ) : null;
};
