import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { AttributeEditForm } from '../components';
import { ROUTES } from 'src/constants';

export const CatalogAttributeEditPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'Редагування атрибуту'}
                        subtitle={'Внесіть необхідні зміни в налаштування атрибуту'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href={ROUTES.CATALOG_ATTRIBUTES}>Атрибути</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Редагування атрибуту
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            <AttributeEditForm/>
        </>
    );
};
