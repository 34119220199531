import './PageHeader.scss';

import React, { ReactElement, ReactNode } from 'react';
import { PageHeader as AntPageHeader } from '@ant-design/pro-components';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface IPageHeaderProps {
    title: string;
    subtitle?: string,
    extra?: ReactNode | ReactNode[],
    breadcrumbs?: ReactElement;
}
export const PageHeader: React.FC<IPageHeaderProps> = ({ title, subtitle, extra, breadcrumbs }) => {
    const handleBackClick = (): void => {
        window.history.back();
    };

    return (
        <div className={'mb-page-header'}>
            <AntPageHeader title={title}
                           subTitle={subtitle}
                           extra={extra}
                           onBack={handleBackClick}
                           backIcon={<ArrowLeftOutlined />}
                           breadcrumb={breadcrumbs}
            />
        </div>
    );
};
