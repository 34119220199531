import React, { useMemo } from 'react';
import { FileUpload } from 'src/base-component';
import { IProductDocument } from 'main/interfaces';
import { UploadFile } from 'antd';

interface IProductDocumentsProps {
    product_id: string;
    documents: IProductDocument[];
    onDelete: (document_id: string) => void;
}

export const ProductDocuments: React.FC<IProductDocumentsProps> = ({ product_id, documents, onDelete }) => {
    const productDocuments: UploadFile[] = useMemo(() => {
        return documents.map((document) => ({
            uid: String(document.id),
            name: document.name,
            status: 'done',
            url: (document?.link ?? ''),
        }));
    }, [documents]);

    const handleDelete = (document_id: string): void => {
        onDelete(document_id);
    };

    return (
        <FileUpload action={'/product/' + product_id + '/document'} files={productDocuments} onRemove={handleDelete}/>
    );
};
