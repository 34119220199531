import React, { SyntheticEvent, useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { IAttribute, IAttributeValue, IProductAttribute } from 'main/interfaces';
import { ApiBaseResponse } from 'src/interfaces';

interface IProductAttributeFormProps {
    attributes: IAttribute[];
    attributeValues: IAttributeValue[] | undefined;
    onCreate: (attribute_id: number, value_id: number) => Promise<ApiBaseResponse<IProductAttribute> | undefined>;
    onChangeAttribute: (attribute_id: number) => void;
    onCreateValue: (attribute_id: number, name: string) => Promise<ApiBaseResponse<IAttributeValue> | undefined>;
}

export const ProductAttributeForm: React.FC<IProductAttributeFormProps> = ({ attributes, onCreate, onChangeAttribute, attributeValues, onCreateValue }) => {
    const [selectedAttribute, setSelectedAttribute] = useState<number>();
    const [selectedValue, setSelectedValue] = useState<number>();
    const [value, setValue] = useState<string>();
    const attributesOptions = attributes.map((attribute) => ({
        value: attribute.id, label: attribute?.info?.[0] ? attribute?.info?.[0].value : ''
    }));
    const attributeValuesOptions = attributeValues?.map((value) => ({
        value: value.id, label: value?.info?.[0] ? value?.info?.[0].value : ''
    }));
    const isDisabledSubmit = !!(selectedAttribute && (!!selectedValue || !!value));

    const filterOption = (input: string, option?: { label: string; value: number }): boolean => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    };

    const handleChangeAttribute = (value: number): void => {
        onChangeAttribute(value);
        setSelectedAttribute(value);
        clearValues();
    };

    const handleChangeValue = (value: number): void => {
        setSelectedValue(value);
        setValue(undefined);
    };

    const handleChangeCreatedValue = (event: SyntheticEvent<HTMLInputElement>): void => {
        setValue(event.currentTarget.value);
        setSelectedValue(undefined);
    };

    const handleSubmit = (): void => {
        if (selectedAttribute) {
            if (selectedValue) {
                onCreate(selectedAttribute, selectedValue).then((res) => {
                    if (res?.status) {
                        clearForm();
                    }
                });
            } else if (value) {
                onCreateValue(selectedAttribute, value).then((res) => {
                    if (res?.data.id) {
                        onCreate(selectedAttribute, res.data.id).then((res) => {
                            if (res?.status) {
                                clearForm();
                            }
                        });
                    }
                });
            }
        }
    };

    const clearForm = (): void => {
        setSelectedAttribute(undefined);
        setSelectedValue(undefined);
        setValue(undefined);
    };

    const clearValues = (): void => {
        setSelectedValue(undefined);
        setValue(undefined);
    };

    return (
        <Form layout={'vertical'}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label="Оберіть атрибут">
                        <Select
                            placeholder=""
                            variant="filled"
                            value={selectedAttribute}
                            onChange={handleChangeAttribute}
                            style={{ flex: 1 }}
                            options={attributesOptions}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item label="Оберіть значення">
                        <Select
                            placeholder=""
                            variant="filled"
                            value={selectedValue}
                            filterOption={filterOption}
                            disabled={!attributeValues}
                            onChange={handleChangeValue}
                            style={{ flex: 1 }}
                            options={attributeValuesOptions}
                            showSearch
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item label="Або створіть нове значення">
                        <Input
                            placeholder=""
                            variant="filled"
                            value={value}
                            disabled={!attributeValues}
                            onChange={handleChangeCreatedValue}
                            style={{ flex: 1 }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" onClick={handleSubmit} disabled={!isDisabledSubmit}>
                        Додати
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
