import React, { useCallback, useEffect, useState } from 'react';
import { IOM_Info } from 'main/services/OrderManager';
import { OrderApi } from 'src/api';
import { OrderHistories, OrderMainDetail, OrderProducts, OrderWarehouseActions } from '../';
import { Spinner } from 'src/base-component';
import { OrderDetailButtons } from './OrderDetailButtons';

interface IOrderDetailProps {
    order_id: string;
}

export const OrderDetail: React.FC<IOrderDetailProps> = ({ order_id }) => {
    const [order, setOrder] = useState<IOM_Info>();
    const [loading, setLoading] = useState(true);

    const getOrder = useCallback((): void => {
        setLoading(true);
        OrderApi.getOne(order_id).then((res) => {
            setOrder(res?.data);
            setLoading(false);
        });
    }, [order_id]);

    const checkDeliveryStatus = useCallback(async (): Promise<void> => {
        setLoading(true);
        await OrderApi.checkDeliveryStatus(order_id);
        getOrder();
    }, [order_id, getOrder]);

    const handleChangeStatus = (): void => {
        getOrder();
    };

    const handleCheckDeliveryStatus = async (): Promise<void> => {
        await checkDeliveryStatus();
    };

    useEffect(() => {
        if (!order) {
            getOrder();
        }
    }, [order, getOrder]);

    if (loading) return <Spinner />;
    if (!order) return null;

    return (
        <>
            <OrderDetailButtons order={order} onChange={handleChangeStatus}/>
            <OrderMainDetail order={order} onChangeStatus={handleChangeStatus} onCheckDeliveryStatus={handleCheckDeliveryStatus}/>
            {order.products && <OrderProducts order_products={order.products}/>}
            {order.history_change && <OrderHistories history_change={order.history_change}/>}
            {order.warehouse_action && <OrderWarehouseActions warehouse_actions={order.warehouse_action}/>}
        </>
    );
};
