import React from 'react';
import { BaseApiPostData, FormChanges } from 'src/interfaces';
import { handleFormChanges } from 'src/helpers';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { ICustomerPartnerInfo, ISpecialty } from 'main/interfaces';
import moment from 'moment';

const { Option } = Select;

interface ICustomerEditPartnerProps {
  partner_info: ICustomerPartnerInfo;
  loading: boolean;
  specialities: ISpecialty[];
  onChange: (values: BaseApiPostData) => void;
}

export const CustomerEditPartner: React.FC<ICustomerEditPartnerProps> = ({ partner_info, loading, specialities, onChange }) => {
  const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
    onChange(allValues);
  });

  const initialValues = {
    ...partner_info,
    pasport_data: moment(partner_info.pasport_data)
  };

  return (
    <Form layout={'vertical'} onValuesChange={handleChange} initialValues={initialValues} disabled={loading}>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'pasport_series'}
                     label="Серія паспорту"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'pasport_organization'}
                     label="Орган що видав документ"
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'pasport_data'}
                     label="Дата видачі документу"
                     rules={[
                       { required: true, message: 'Поле повинно бути заповнене' },
                     ]}
                     hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'address_register'}
                     label="Адреса реєстрації"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'ipn'}
                     label="ІПН"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'id_specialty'}
                     label="Спеціалізація партнера"
                     hasFeedback
          >
            <Select placeholder="Оберіть варіант із списку">
              {specialities?.map((speciality) => (
                <Option value={speciality.id} key={speciality.id}>
                  {speciality.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'bank_name'}
                     label="Назва банку"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'bank_code'}
                     label="Код банку"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'bank_iban'}
                     label="IBAN банку"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
