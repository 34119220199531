import { BaseApi } from '.';
import { IUser } from 'main/interfaces';
import { ApiBaseResponse, BaseApiPostData } from 'src/interfaces';

class AuthApi extends BaseApi {
    constructor() {
        super('/auth');
    }

    async profile(): Promise<ApiBaseResponse<IUser> | undefined> {
        return (await this.GET<Promise<ApiBaseResponse<IUser>> | undefined>('/profile'))?.data;
    }

    async login(data: BaseApiPostData): Promise<ApiBaseResponse<IUser> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IUser>>('/login', data))?.data);
    }
}

export const authApi = new AuthApi();
