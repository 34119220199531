import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { ProductSeoForm } from '../';
import { ProductApi } from 'src/api';
import { IProduct, IProductInfo } from 'main/interfaces';
import { ApiBaseResponse, FormChanges } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';

interface IProductSeoTabProps {
    product_id: string;
}

export const ProductSeoTab: React.FC<IProductSeoTabProps> = ({ product_id }) => {
    const [productInfo, setProductInfo] = useState<IProductInfo[] | undefined>();

    const getProductInfo = useCallback((): void => {
        ProductApi.getInfo(product_id).then((res) => {
            setProductInfo(res?.data);
        });
    }, [product_id]);

    const handleUpdateProductInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<IProduct> | undefined> => {
        const response = await ProductApi.updateInfo(Number(product_id), { ...changedValues, id_language: language });
        handleChangeStatus<IProduct>(response);
        getProductInfo();
        return response;
    }, [product_id, getProductInfo]);

    useEffect(() => {
        if (!productInfo) {
            getProductInfo();
        }
    }, [getProductInfo, productInfo]);

    return productInfo ? (
        <>
            <Divider children={'SEO дані'} orientation="left" orientationMargin="0"/>
            <ProductSeoForm info={productInfo} onUpdate={handleUpdateProductInfo} />
        </>
    ) : null;
};
