import { IEntryPoint, IRouteItem } from 'src/interfaces';
import { ROUTES } from 'src/constants';
import {
  RelatedProductsPage,
} from './pages';

const routeList: IRouteItem[] = [
  {
    path: ROUTES.SETTINGS_RELATED_PRODUCTS,
    exact: true,
    element: RelatedProductsPage,
  },
];

export const SettingsApp: IEntryPoint = {
  route: routeList,
};
