import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { IProduct, IProductRecommend } from 'main/interfaces';
import { ProductApi } from 'src/api';

interface IProductRecommendsFormProps {
    products: IProduct[];
    productRecommends: IProductRecommend[];
    onCreate: (recommend_id: number) => void;
    onDelete: (recommend_id: number) => void;
    onSearch: (value: string) => void;
}

export const ProductRecommendsForm: React.FC<IProductRecommendsFormProps> = ({ products, productRecommends, onSearch, onCreate, onDelete }) => {
    const [productRecommendsOptions, setProductRecommendsOptions] = useState<{ value: number, label: string }[]>();
    const productOptions = products.map((product) => ({
        value: product.id,
        label: product?.info?.[0]
            ? product.info?.[0].name
            : ''
    }));
    const defaultValues = useMemo(() => productRecommends.map((option) => Number(option.id_product_recommend)), [productRecommends]);
    const [selected, setSelected] = useState<number[]>(defaultValues);

    const handleChange = (value: number[]): void => {
        selected.forEach((tag) => {
            if (!value.includes(tag)) {
                onDelete(tag);
            }
        });
        value.forEach((tag) => {
            if (!selected.includes(tag)) {
                onCreate(tag);
            }
        });
        setSelected(value);
    };

    const handleSearch = (value: string):void => {
        onSearch(value);
    };

    useEffect(() => {
        if (!productRecommendsOptions) {
            ProductApi.getAll({ id: defaultValues.join(',') }).then((res) => {
                setProductRecommendsOptions(res?.data?.rows.map((product) => ({
                    value: product.id,
                    label: product?.info?.[0]
                      ? product.info?.[0].name
                      : ''
                })));
            });
        }
    }, [productRecommendsOptions, defaultValues]);

    if (!products || !productRecommendsOptions) return null;

    console.log([...productOptions, ...productRecommendsOptions]);

    return (
        <Form layout={'vertical'}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={10}>
                    <Form.Item label="Рекомендовані товари">
                        <Select
                            mode="multiple"
                            placeholder=""
                            variant="filled"
                            value={selected}
                            filterOption={(value, option) => option?.label.includes(value) ?? false}
                            onSearch={handleSearch}
                            onChange={handleChange}
                            style={{ flex: 1 }}
                            options={[...productOptions, ...productRecommendsOptions]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
