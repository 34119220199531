import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { CustomerGrid } from '../components';

export const CustomersPage: React.FC = () => {
  const navigate = useNavigate();

  const handleAddCustomer = (): void => {
    navigate(ROUTES.CUSTOMERS_ADD);
  };

  return (
    <>
      <PageHeader title={'Клієнти'}
                  subtitle={'Перегляд клієнтів компанії'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Клієнти
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
                  extra={[
                    <Button key="1" type="primary" onClick={handleAddCustomer}>
                      <PlusCircleOutlined /> Додати
                    </Button>
                  ]}
      />
      <CustomerGrid/>
    </>
  );
};
