import React from 'react';
import { IOM_OrderProduct } from 'main/services/OrderManager';
import { Table } from 'src/base-component';
import { Typography, TableProps, Space } from 'antd';
import { ROUTES } from 'src/constants';
import { Formatter } from 'src/helpers';

const { Title } = Typography;

interface IOrderProductsProps {
    order_products: IOM_OrderProduct[];
}

export const OrderProducts: React.FC<IOrderProductsProps> = ({ order_products }) => {
    const columns: TableProps<IOM_OrderProduct>['columns'] = [
        {
            title: 'Назва',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (<a href={ROUTES.CATALOG_PRODUCTS + '/edit/' + record.id_product}>{record.name}</a>)
        },
        {
            title: 'Модель',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Кількість',
            dataIndex: 'count',
            key: 'count',
            render: (_, record) => `${record.count} шт.`,
        },
        {
            title: 'Ціна',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => Formatter.toMoney(record.price),
        },
    ];

    return (
        <>
            <Title level={5}>Товари в замовлені:</Title>
            <Space/>
            <Table columns={columns} data={order_products} loading={false}/>
        </>
    );
};
