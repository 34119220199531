import React from 'react';
import { Col, Form, InputNumber, Row } from 'antd';
import { handleFormChanges } from 'src/helpers';
import { FormChanges } from 'src/interfaces';
import { IAttribute } from 'main/interfaces';

interface IAttributeGeneralInfoFormProps {
    attribute?: IAttribute;
    onUpdate: (changedValues: FormChanges) => void
}

export const AttributeGeneralInfoForm: React.FC<IAttributeGeneralInfoFormProps> = ({ attribute, onUpdate }) => {
    const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        onUpdate(allValues);
    });

    return (
        <Form layout={'vertical'} onValuesChange={handleChange} initialValues={attribute}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'sort'}
                               label="Порядок сортування"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <InputNumber/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
