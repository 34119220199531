import React, { useEffect, useState } from 'react';
import {
    Button,
    Form,
    Input,
    Select,
    Space,
    Switch,
} from 'antd';
import { CATEGORY_FIELDS } from 'src/interfaces';
import { CategoryApi } from 'src/api';
import { handleChangeStatus, makeTransliteration } from 'src/helpers';
import { ICategory } from 'main/interfaces';

const { Option } = Select;
export const CategoryAddForm: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [categories, setCategories] = useState<ICategory[] | undefined>();

    const nameField = Form.useWatch('name', form);

    const handleFinish = (values: Record<string, string | boolean | number>): void => {
        setLoading(true);
        CategoryApi.create(values).then((res) => {
            handleChangeStatus<ICategory>(res);
            if (res?.status) form.resetFields();
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!categories) {
            CategoryApi.getAll({}).then((res) => {
                setCategories(res?.data?.rows);
            });
        }
    }, [categories]);

    useEffect(() => {
        form.setFieldValue('alias', makeTransliteration(nameField));
    }, [form, nameField]);

    return (
        <Form
            name="validate_other"
            onFinish={handleFinish}
            form={form}
            labelCol={{ span: 6 }}
            disabled={loading}
            wrapperCol={{ span: 14 }}
            initialValues={{
                show_menu: false,
            }}
            style={{ maxWidth: 900 }}
        >
            <Form.Item name={CATEGORY_FIELDS.NAME}
                       label="Назва"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item name={CATEGORY_FIELDS.ALIAS}
                       label="Аліас"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={CATEGORY_FIELDS.PARENT}
                label="Батьківська категорія"
                hasFeedback
                rules={[]}
            >
                <Select placeholder="Оберіть варіант із списку">
                    {categories?.map((category) => (<Option value={category.id} key={category.id}>{category?.info?.[0] ? category.info[0]?.value : ''}</Option>))}
                </Select>
            </Form.Item>
            <Form.Item name={CATEGORY_FIELDS.SHOW_MENU}
                       label="Відображати в меню"
            >
                <Switch />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Створити
                    </Button>
                    <Button htmlType="reset">Скинути</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
