import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { AuthApi } from 'src/api';
import { IUser } from 'main/interfaces';
import './AuthForm.scss';

interface IAuthFormProps {
    onSubmit: (user: IUser | null) => void;
}

export const AuthForm: React.FC<IAuthFormProps> = ({ onSubmit }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleSubmit = (values: Record<string, string | boolean | number>): void => {
        setIsLoading(true);
        AuthApi.login(values).then((res) => {
            onSubmit(res?.data ?? null);
            setIsLoading(false);
        });
    };

    return (
        <Row>
            <Col span={24} className="login-container">
                <Form
                    name="normal_login"
                    className="login-form"
                    disabled={isLoading}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Введіть Ваш e-mail',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Введіть Ваш пароль',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Пароль"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Авторизуватись
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};
