import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'src/base-component';
import { Button, Space, Switch, TableProps } from 'antd';
import { CopyFilled, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { IProduct } from 'main/interfaces';
import { ProductApi } from 'src/api';
import { ROUTES } from 'src/constants';
import { usePagination } from 'src/hook';
import { IBaseGetAllParams } from '@/interfaces';
import { Formatter } from 'src/helpers';

export const ProductGrid: React.FC = () => {
    const [data, setData] = useState<IProduct[]>();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { pagination, handlePagination } = usePagination<IProduct>();
    const paginationParams = useMemo(() => ({ page: pagination?.current ?? 1 }), [pagination]);

    const getProducts = useCallback((params: IBaseGetAllParams): void => {
        setLoading(true);
        ProductApi.getAll(params).then((res) => {
            setData(res?.data?.rows ?? []);
            handlePagination(res, getProducts, Number(params?.page));
            setLoading(false);
        });
    }, [handlePagination]);

    const handleEdit = (id: number): () => void => {
        return () => navigate(ROUTES.CATALOG_PRODUCTS + '/edit/' + id);
    };

    // const handleDelete = (id: number): () => void => {
    //     return () => {
    //         setLoading(true);
    //         ProductApi.delete(String(id)).then((res) => {
    //             if (res?.data?.success) {
    //                 getProducts();
    //             } else {
    //                 setLoading(false);
    //             }
    //         });
    //     };
    // };

    const handleCreate = (id: number): () => void => {
        return () => {
            setLoading(true);
            ProductApi.copy(String(id)).then((res) => {
                if (res?.data?.id) {
                    getProducts(paginationParams);
                } else {
                    setLoading(false);
                }
            });
        };
    };

    const handleActive = (id: number, is_active: boolean): () => void => {
        return () => {
            setLoading(true);
            ProductApi.update(Number(id), { is_active }).then((res) => {
                if (res?.data?.id) {
                    getProducts(paginationParams);
                } else {
                    setLoading(false);
                }
            });
        };
    };

    const columns: TableProps<IProduct>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Назва',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                if (record?.info) {
                    return record.info[0].name;
                }
                return null;
            }
        },
        {
            title: 'Модель',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Аліас',
            dataIndex: 'alias',
            key: 'alias',
            render: (_, record) => record?.alias?.length > 50 ? `${record.alias.slice(0, 50)}...` : record.alias
        },
        {
            title: 'Ціна',
            dataIndex: 'price',
            key: 'price',
            width: '100px',
            render: (_, record) => Formatter.toMoney(record.price),
        },
        {
            title: 'Ціна за одиницю',
            dataIndex: 'price_one',
            key: 'price_one',
            render: (_, record) => Formatter.toMoney(record.price_one),
        },
        {
            title: 'Активність',
            dataIndex: 'active',
            key: 'active',
            render: (_, record) => (
                <Switch checked={Boolean(record.is_active)} onChange={handleActive(record.id, !record.is_active)}/>
            ),
        },
        {
            title: 'Дії',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={handleEdit(record.id)}>
                        <EditOutlined/>
                    </Button>
                    {/*<Button onClick={handleDelete(record.id)}>*/}
                    {/*    <DeleteOutlined />*/}
                    {/*</Button>*/}
                    <Button onClick={handleCreate(record.id)}>
                        <CopyFilled/>
                    </Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (!data) {
            getProducts(paginationParams);
        }
    }, [getProducts, paginationParams, data]);

    return (
        <Table columns={columns} data={data ?? []} loading={loading} pagination={pagination}/>
    );
};
