import React from 'react';
import { Form, Input, Select, Switch } from 'antd';
import { handleFormChanges } from 'src/helpers';
import { CATEGORY_FIELDS, FormChanges } from 'src/interfaces';
import { ICategory } from 'main/interfaces';

const { Option } = Select;

interface ICategoryGeneralInfoFormProps {
    categories: ICategory[];
    category: ICategory;
    onUpdate: (changedValues: FormChanges) => void
}

export const CategoryGeneralInfoForm: React.FC<ICategoryGeneralInfoFormProps> = ({ categories, category, onUpdate }) => {
    const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        onUpdate(allValues);
    });

    return (
        <Form layout={'vertical'} onValuesChange={handleChange} initialValues={category}>
            <Form.Item name={CATEGORY_FIELDS.ALIAS}
                       label="Аліас"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={CATEGORY_FIELDS.PARENT}
                label="Батьківська категорія"
                hasFeedback
                rules={[]}
            >
                <Select placeholder="Оберіть варіант із списку">
                    {categories?.filter((parentCategory) => parentCategory.id !== category.id).map((category) => (<Option value={category.id} key={category.id}>{category?.info?.[0] ? category.info[0]?.value : ''}</Option>))}
                </Select>
            </Form.Item>
            <Form.Item name={CATEGORY_FIELDS.SHOW_MENU}
                       label="Відображати в меню"
            >
                <Switch />
            </Form.Item>
        </Form>
    );
};
