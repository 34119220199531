export enum MENU_KEYS {
    CATALOG = 'catalog',
    CATALOG_PRODUCT = 'catalog_product',
    ORDERS = 'orders',
    SETTINGS = 'settings',
    ECONOMICAL = 'economical',
    WAREHOUSES_MAIN = 'warehouses_main',
    WAREHOUSES = 'warehouses',
    CUSTOMERS = 'customers',
}
