import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { ProductAddForm } from '../components';

export const CatalogProductAddPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'Створення продукту'}
                        subtitle={'Заповніть форму щоб створити новий продукт'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href="/products">Продукти</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Створення продукту
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            <ProductAddForm/>
        </>
    );
};
