import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CategoryGrid } from '../components';
import { ROUTES } from 'src/constants';

export const CatalogCategoriesPage: React.FC = () => {
    const navigate = useNavigate();

    const handleAddProduct = (): void => {
        navigate(ROUTES.CATALOG_CATEGORIES_ADD);
    };

    return (
        <>
            <PageHeader title={'Категорії'}
                        subtitle={'Перегляд категорій товарів'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Категорії
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
                        extra={[
                            <Button key="1" type="primary" onClick={handleAddProduct}>
                                <PlusCircleOutlined /> Додати
                            </Button>
                        ]}
            />
            <CategoryGrid/>
        </>
    );
};
