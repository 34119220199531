import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IWarehouseLot } from 'main/interfaces';
import { usePagination } from 'src/hook';
import { WarehouseApi } from 'src/api';
import { TableProps } from 'antd';
import { Table } from 'src/base-component';
import { WarehouseLotsProducts } from './WarehouseLotsProducts';
import { ROUTES } from 'src/constants';
import { IBaseGetAllParams } from 'src/interfaces';

interface IWarehouseLotsProps {
  warehouse_id: string;
}

export const WarehouseLots: React.FC<IWarehouseLotsProps> = ({ warehouse_id }) => {
  const [data, setData] = useState<IWarehouseLot[]>();
  const [loading, setLoading] = useState(true);
  const { pagination, handlePagination } = usePagination<IWarehouseLot>();
  const formattedData = data?.map((product) => ({ ...product, key: Number(product.id_product) }));
  const paginationParams = useMemo(() => ({ page: pagination?.current ?? 1, pageSize: pagination?.pageSize ?? 10 }), [pagination]);

  const getProducts = useCallback((params: IBaseGetAllParams): void => {
    setLoading(true);
    WarehouseApi.getProducts(warehouse_id, Number(params?.page)).then((res) => {
      setData(res?.data?.rows ?? []);
      handlePagination(res, getProducts, Number(params?.page));
      setLoading(false);
    });
  }, [warehouse_id, handlePagination]);

  const columns: TableProps<IWarehouseLot>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id_product',
      key: 'id_product',
    },
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <a href={`${ROUTES.CATALOG_PRODUCTS}/edit/${record.id_product}`}>{record.name}</a>
    },
    {
      title: 'Модель',
      dataIndex: 'model',
      key: 'model',
    },
  ];

  const handleExpandable = (record: IWarehouseLot): ReactNode => {
    return <WarehouseLotsProducts products={record?.rows ?? []} />;
  };

  useEffect(() => {
    if (!data) {
      getProducts(paginationParams);
    }
  }, [getProducts, paginationParams, data]);

  return (
    // @ts-ignore
    <Table columns={columns} data={formattedData} loading={loading} onExpandable={handleExpandable} pagination={pagination}/>
  );
};
