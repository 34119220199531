import { BaseApi } from '.';
import { IManufacturer } from 'main/interfaces';
import { ApiBaseResponse, ApiWithPaginationResponse } from 'src/interfaces';

class ManufacturerApi extends BaseApi {
    constructor() {
        super('/manufacturer');
    }

    async getAll(): Promise<ApiBaseResponse<ApiWithPaginationResponse<IManufacturer>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<IManufacturer>>>())?.data);
    }
}

export const manufacturerApi = new ManufacturerApi();
