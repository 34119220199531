import React from 'react';
import { Table } from 'src/base-component';
import { Button, Space, TableProps } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { IOrderProductLot } from 'main/interfaces';
import { OrderEditProductsLotsCount } from './OrderEditProductsLotsCount';
import { ApiBaseResponse, BaseApiPostData } from 'src/interfaces';
import { OrderEditProductsLotsAdd } from './OrderEditProductsLotsAdd';
import { Formatter } from 'src/helpers';

export interface IOrderEditProductsLotsProps {
    product_id: number;
    order_products_lots: IOrderProductLot[];
    onDelete: (id: number) => void;
    disabled: boolean;
    onChange: (order_product_lot_id: number, values: BaseApiPostData) => void;
    onAdd: (values: BaseApiPostData) => Promise<ApiBaseResponse<Record<string, string>> | undefined>;
}

export const OrderEditProductsLots: React.FC<IOrderEditProductsLotsProps> = ({ product_id, order_products_lots, disabled, onAdd, onDelete, onChange }) => {
    const handleDelete = (order_product_id: number): () => void => {
        return (): void => {
            onDelete(order_product_id);
        };
    };

    const handleChange = (order_product_lot_id: number, order_product_lot: BaseApiPostData): void => {
        onChange(order_product_lot_id, order_product_lot);
    };

    const handleAdd = (product: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
        return onAdd(product);
    };

    const columns: TableProps<IOrderProductLot>['columns'] = [
        {
            title: 'Назва лоту',
            dataIndex: 'info',
            key: 'info',
            render: (_, record) => record?.info?.lot ?? '',
        },
        {
            title: 'Кількість',
            dataIndex: 'count',
            key: 'count',
            render: (_, record) => disabled ? record.count : (
                <OrderEditProductsLotsCount lot={record} max={record?.info ? record?.info?.count - record?.info?.count_reserved : null} onChange={handleChange}/>
            ),
        },
        {
            title: 'Термін придатності',
            dataIndex: 'date_to',
            key: 'date_to',
            render: (_, record) => Formatter.toDate(record?.info?.date_to ?? ''),
        },
        {
            title: 'Дії',
            key: 'action',
            render: (_, record) => disabled ? null : (
                <Button onClick={handleDelete(record.id)}>
                    <DeleteFilled/>
                </Button>
            ),
        },
    ];

    return (
        <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
            <Table columns={columns} data={order_products_lots} loading={false}/>
            {!disabled && <OrderEditProductsLotsAdd product_id={product_id} onAdd={handleAdd}/>}
        </Space>
    );
};
