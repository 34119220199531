import React from 'react';
import { withLanguageTabs } from 'src/hoc';
import { Col, Form, Input, Row } from 'antd';
import { IAttributeValue } from 'main/interfaces';
import { handleFormChanges } from 'src/helpers';
import { FormChanges } from 'src/interfaces';

interface IAttributeItemInfoForm {
    language?: number;
    item?: IAttributeValue;
    onCreate: (attribute_value_id: number, changes: Record<string, string | number | boolean>, language: number) => void;
    onUpdate: (attribute_value_id: number, changes: Record<string, string | number | boolean>, language: number) => void;
}

const AttributeItemInfoForm: React.FC<IAttributeItemInfoForm> = ({ language, item, onUpdate, onCreate }) => {
    const attributeInfo = item?.info?.find((item) => item.id_language === language);
    const initialValues = {
        name: attributeInfo?.value,
    };

    const handleUpdates = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        if (language) {
            if (attributeInfo) {
                item?.id && onUpdate(item?.id, allValues, language);
            } else {
                item?.id && onCreate(item.id, allValues, language);
            }
        }
    });

    return initialValues ? (
        <Form layout={'vertical'} initialValues={initialValues} onValuesChange={handleUpdates}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item name="name"
                               label="Назва"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    ) : null;
};

export const attributeItemInfoForm = withLanguageTabs<IAttributeItemInfoForm>(AttributeItemInfoForm);
