import React from 'react';
import {
  Button, DatePicker,
  Form,
  Input,
  Select,
  Space
} from 'antd';
import { ICustomerPartnerInfo, ISpecialty } from 'main/interfaces';
import { ApiBaseResponse, BaseApiPostData } from '@/interfaces';

const { Option } = Select;

interface ICustomerRegisterPartnerProps {
  specialities: ISpecialty[];
  onSuccess: (values: BaseApiPostData) => Promise<ApiBaseResponse<ICustomerPartnerInfo> | undefined>;
}

export const CustomerRegisterPartner: React.FC<ICustomerRegisterPartnerProps> = ({ specialities, onSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleFinish = (values: Record<string, string | boolean | number>): void => {
    setLoading(true);
    onSuccess(values).then((res) => {
      if (res?.status) form.resetFields();
      setLoading(false);
    });
  };

  return (
    <Form
      name="validate_other"
      onFinish={handleFinish}
      form={form}
      labelCol={{ span: 6 }}
      disabled={loading}
      wrapperCol={{ span: 14 }}
      initialValues={{
        show_menu: false,
      }}
      style={{ maxWidth: 900 }}
    >
      <Form.Item name={'pasport_series'}
                 label="Серія паспорту"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'pasport_organization'}
                 label="Орган що видав документ"
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'pasport_data'}
                 label="Дата видачі документу"
                 rules={[
                   { required: true, message: 'Поле повинно бути заповнене' },
                 ]}
                 hasFeedback
      >
        <DatePicker />
      </Form.Item>
      <Form.Item name={'address_register'}
                 label="Адреса реєстрації"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'ipn'}
                 label="ІПН"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'id_specialty'}
                 label="Спеціалізація партнера"
                 hasFeedback
      >
        <Select placeholder="Оберіть варіант із списку">
          {specialities?.map((speciality) => (
            <Option value={speciality.id} key={speciality.id}>
              {speciality.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={'bank_name'}
                 label="Назва банку"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'bank_code'}
                 label="Код банку"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>
      <Form.Item name={'bank_iban'}
                 label="IBAN банку"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input/>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Space>
          <Button type="primary" htmlType="submit">
            Створити
          </Button>
          <Button htmlType="reset">Скинути</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
