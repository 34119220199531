
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { ProductApi } from 'src/api';
import { IProductImage } from 'main/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { ProductImages } from '../';
interface IProductImagesTabProps {
    product_id: string;
}

export const ProductImagesTab: React.FC<IProductImagesTabProps> = ({ product_id }) => {
    const [productImages, setProductImages] = useState<IProductImage[] | undefined>();

    const getProductImages = useCallback(() => {
        ProductApi.getImages(product_id).then((res) => {
            setProductImages(res?.data ?? []);
        });
    }, [product_id]);

    const handleDeleteImage = useCallback(async (image_id: string) => {
        const response = await ProductApi.deleteImage(Number(product_id), Number(image_id));
        handleChangeStatus<IProductImage>(response);
        getProductImages();
        return response;
    }, [product_id, getProductImages]);

    useEffect(() => {
        if (!productImages) {
            getProductImages();
        }
    }, [productImages, getProductImages]);

    return productImages ? (
        <Row gutter={31}>
            <Col xs={24} sm={24} md={24} lg={13}>
                <Divider children={'Зображення товара'} orientation="left" orientationMargin="0"/>
                <ProductImages product_id={product_id} images={productImages} onDelete={handleDeleteImage}/>
            </Col>
        </Row>
    ) : null;
};
