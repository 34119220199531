
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { ProductApi } from 'src/api';
import { IProductDocument } from 'main/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { ProductDocuments } from '../';
interface IProductDocumentsTabProps {
    product_id: string;
}

export const ProductDocumentsTab: React.FC<IProductDocumentsTabProps> = ({ product_id }) => {
    const [productDocuments, setProductDocuments] = useState<IProductDocument[] | undefined>();

    const getProductDocuments = useCallback(() => {
        ProductApi.getDocuments(product_id).then((res) => {
            setProductDocuments(res?.data ?? []);
        });
    }, [product_id]);

    const handleDeleteDocument = useCallback(async (document_id: string) => {
        const response = await ProductApi.deleteDocument(Number(product_id), Number(document_id));
        handleChangeStatus<IProductDocument>(response);
        getProductDocuments();
        return response;
    }, [product_id, getProductDocuments]);

    useEffect(() => {
        if (!productDocuments) {
            getProductDocuments();
        }
    }, [productDocuments, getProductDocuments]);

    return productDocuments ? (
        <Row gutter={31}>
            <Col xs={24} sm={24} md={24} lg={13}>
                <Divider children={'Зображення товара'} orientation="left" orientationMargin="0"/>
                <ProductDocuments product_id={product_id} documents={productDocuments} onDelete={handleDeleteDocument}/>
            </Col>
        </Row>
    ) : null;
};
