import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb, Button } from 'antd';
import { WarehouseActions } from '../components';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants';

export const WarehouseActionsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleAddProduct = (): void => {
    navigate(ROUTES.WAREHOUSES_ACTIONS_ADD);
  };

  return (
    <>
      <PageHeader title={'Рух на складах'}
                  subtitle={'Перегляд руху товарів компанії'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Рух на складах
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
                  extra={[
                    <Button key="1" type="primary" onClick={handleAddProduct}>
                      <PlusCircleOutlined /> Додати
                    </Button>
                  ]}
      />
      <WarehouseActions />
    </>
  );
};
