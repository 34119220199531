import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import { CustomerEdit } from '../components';
import { ROUTES } from 'src/constants';

export const CustomerEditPage: React.FC = () => {
  const { id } = useParams();

  return (
    <>
      <PageHeader title={'Редагування клієнта #' + id}
                  subtitle={'Детальний перегляд замовлення'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <a href={ROUTES.CUSTOMERS}>Клієнти</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Редагування клієнта №{id}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
      />
      {id && <CustomerEdit customer_id={id}/>}
    </>
  );
};
