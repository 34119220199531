import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.scss';

import React, { useEffect, useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

interface ITextEditorProps {
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    onBlur?: (value: string) => void;
}

export const TextEditor: React.FC<ITextEditorProps> = (
    {
        value,
        onChange,
        disabled,
        onBlur,
    }) => {
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );
    const [blockUpdate, setBlockUpdate] = useState<boolean>(false);
    const handleChange = (content: EditorState): void => {
        setBlockUpdate(true);
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        setEditorState(content);
    };

    const handleBlur = (): void => {
        onBlur && onBlur(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    useEffect(() => {
        if (value && !editorState.getCurrentContent().hasText() && !blockUpdate) {
            setEditorState(() => {
                return EditorState.createWithContent(stateFromHTML(value));
            });
        }
    }, [value, blockUpdate, editorState]);

    return (
        <Editor
            editorState={editorState}
            wrapperClassName="mb-text-editor-wrapper"
            readOnly={disabled}
            onEditorStateChange={handleChange}
            onBlur={handleBlur}
        />
    );
};
