import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { AttributeAddForm } from '../components';
import { ROUTES } from 'src/constants';

export const CatalogAttributeAddPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'Створення атрибуту'}
                        subtitle={'Заповніть форму щоб створити новий атрибут'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href={ROUTES.CATALOG_ATTRIBUTES}>Атрибути</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Створення атрибуту
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            <AttributeAddForm/>
        </>
    );
};
