import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { CategoryAddForm } from '../components';
import { ROUTES } from 'src/constants';

export const CatalogCategoryAddPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'Створення категорії'}
                        subtitle={'Заповніть форму щоб створити нову категорію'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href={ROUTES.CATALOG_CATEGORIES}>Категорії</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Створення категорії
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            <CategoryAddForm/>
        </>
    );
};
