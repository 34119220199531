import React from 'react';
import { TableProps } from 'antd';
import { Table } from 'src/base-component';
import { IWarehouseActionProduct } from 'main/interfaces';

interface IOrderWarehouseActionsProductsProps {
  action_products: IWarehouseActionProduct[];
}

export const OrderWarehouseActionsProducts: React.FC<IOrderWarehouseActionsProductsProps> = ({ action_products }) => {
  const columns: TableProps<IWarehouseActionProduct>['columns'] = [
    {
      title: 'Товар',
      key: 'product',
      render: (_, record) => record?.product?.info ? record?.product?.info[0]?.name : '-',
    },
    {
      title: 'Модель',
      key: 'model',
      render: (_, record) => record?.product?.model ?? '-',
    },
    {
      title: 'Склад',
      key: 'product',
      render: (_, record) => record?.warehouse?.name ?? '-',
    },
    {
      title: 'Кількість',
      key: 'count',
      dataIndex: 'count',
    },
    {
      title: 'Лот',
      key: 'product',
      render: (_, record) => record?.warehouse_product_lot?.lot ?? '-',
    },
  ];

  return (
    <Table columns={columns} data={action_products} loading={false}/>
  );
};
