import React, { useEffect, useMemo } from 'react';
import { AuthForm } from './AuthForm';
import { IUser } from 'main/interfaces';
import { AuthApi } from 'src/api';
import { Spinner } from 'src/base-component';
import { AuthContext } from './AuthContext';

interface IAuthProps {
    children: React.ReactNode;
}

export const Auth: React.FC<IAuthProps> = ({ children }) => {
    const [profile, setProfile] = React.useState<IUser | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const auth = useMemo(() => ({ user: profile, setUser: setProfile }), [profile]);

    const handleSubmit = (user: IUser | null): void => {
        setProfile(user);
        setIsLoading(false);
    };
    useEffect(() => {
        if (!profile && isLoading) {
            AuthApi.profile().then((res) => {
                setProfile(res?.data ?? null);
                setIsLoading(false);
            });
        }
    }, [isLoading, profile]);

    if (isLoading) return <Spinner/>;

    return profile
        ? <AuthContext.Provider value={auth}>
            {children}
          </AuthContext.Provider>
        : <AuthForm onSubmit={handleSubmit}/>;
};
