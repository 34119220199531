import { IOM_Order } from 'main/services/OrderManager';
import { ORDER_STATUSES } from 'src/interfaces';

export class OrderConditions {
  static isOrderBeingCompleted(order: IOM_Order): boolean {
    return order?.id_status === ORDER_STATUSES.COMPLETED;
  }

  static needToShowTTNButton(order: IOM_Order): boolean {
    return OrderConditions.isOrderBeingCompleted(order) && !order.ttn;
  }
}
