import React, { useEffect, useState } from 'react';
import { IWarehouse, IWarehouseProductLot } from 'main/interfaces';
import { Tag, Button, Col, Form, InputNumber, Row, Select, Space, Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { ApiBaseResponse, BaseApiPostData } from 'src/interfaces';
import { WarehouseApi } from 'src/api';
import { Formatter, sortByDate } from 'src/helpers';

interface IOrderEditProductsAddProps {
    product_id: number;
    onAdd: (product: BaseApiPostData) => Promise<ApiBaseResponse<Record<string, string>> | undefined>;
}

export const OrderEditProductsLotsAdd: React.FC<IOrderEditProductsAddProps> = ({ product_id, onAdd }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [lots, setLots] = useState<IWarehouseProductLot[]>([]);
    const [warehouses, setWarehouses] = useState<IWarehouse[]>();

    const warehouseField = Form.useWatch('id_warehouse', form);
    const lotField = Form.useWatch('id_lot', form);
    const currentLot = lots?.find((lot) => lot.id === lotField);
    const maxCountByLot = currentLot ? (currentLot?.count - currentLot?.count_reserved) : null;

    const warehousesOptions = warehouses?.map((warehouse) => ({
        value: warehouse.id,
        label: warehouse.name
    }));

    const lotsOptions = lots.sort(sortByDate<IWarehouseProductLot>('date_to', 'ASC')).map((lot) => ({
        value: lot.id,
        label: <Space>{lot.lot} <Tag>{Formatter.toDate(lot.date_to, true)}</Tag></Space>,
        description: lot.lot,
    }));

    const handleFinish = (values: Record<string, string | boolean | number>): void => {
        setLoading(true);
        onAdd(values).then((res) => {
            if (res?.status) form.resetFields();
            setLoading(false);
        });
    };

    useEffect(() => {
        if (warehouseField && product_id) {
            WarehouseApi.getOne(warehouseField, product_id).then((res) => {
                setLots(res?.data?.lots ?? []);
            });
        }
    }, [product_id, warehouseField]);

    useEffect(() => {
        if (!warehouses && product_id) {
            setLoading(true);
            WarehouseApi.getAll({ type: 'STORE,INFO', id_product: product_id }).then((res) => {
                setWarehouses(res?.data?.rows ?? []);
                setLoading(false);
            });
        }
    }, [product_id, warehouses]);

    if (!warehouses) return null;

    return (
        <Form
            name="validate_other"
            onFinish={handleFinish}
            form={form}
            layout={'horizontal'}
            disabled={loading}
        >
            <Row gutter={15}>
                <Col span={5}>
                    <Form.Item label="Склад"
                               name={'id_warehouse'}
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Select
                            showSearch
                            placeholder=""
                            filterOption={(value, option) => option?.label.includes(value) ?? false}
                            options={warehousesOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Лот"
                               name={'id_lot'}
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Select
                            showSearch
                            placeholder=""
                            filterOption={(value, option) => option?.description.includes(value) ?? false}
                            options={lotsOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Space>
                        <Form.Item label="Кількість"
                                   name={'count'}
                                   rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                                   hasFeedback
                        >
                            <InputNumber addonAfter={'шт.'} />
                        </Form.Item>
                        {maxCountByLot !== null && <Typography.Text type={'secondary'}>Допустимо на складі: {maxCountByLot}</Typography.Text>}
                    </Space>
                </Col>
                <Col span={4}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            <PlusCircleFilled/>
                            Додати
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
