import React, { useState } from 'react';
import { Button, Col, Form, InputNumber, Row } from 'antd';
import { IProductDiscount } from 'main/interfaces';
import { ApiBaseResponse } from 'src/interfaces';

interface IProductDiscountFormProps {
    onCreate: (discount: number) => Promise<ApiBaseResponse<IProductDiscount> | undefined>;
}

export const ProductDiscountForm: React.FC<IProductDiscountFormProps> = ({ onCreate }) => {
    const [value, setValue] = useState<number>();

    const handleChangeValue = (value: number | null): void => {
        setValue(value ?? undefined);
    };

    const handleSubmit = (): void => {
        if (value) {
            onCreate(value).then((res) => {
                if (res?.status) {
                    setValue(undefined);
                }
            });
        }
    };

    return (
        <Form layout={'horizontal'}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label="Введіть вартість товару з знижкою">
                        <InputNumber
                            min={1}
                            value={value}
                            onChange={handleChangeValue}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" onClick={handleSubmit} disabled={value === undefined}>
                        Додати
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
