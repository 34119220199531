import './Content.scss';

import React from 'react';
import { Layout } from 'antd';

const AntContent = Layout.Content;

interface IContentProps {
    children: React.ReactNode;
}

export const Content: React.FC<IContentProps> = ({ children }) => {
    return (
        <AntContent className={'mb-content'}>
            {children}
        </AntContent>
    );
};
