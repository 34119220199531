import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { TagEditForm } from '../components';
import { ROUTES } from 'src/constants';

export const CatalogTagEditPage: React.FC = () => {
    return (
        <>
            <PageHeader title={'Редагування тегу'}
                        subtitle={'Внесіть необхідні зміни в налаштування тега'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href={ROUTES.CATALOG_TAG}>Теги</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Редагування тегу
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
            />
            <TagEditForm/>
        </>
    );
};
