import React, { useCallback, useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Tag } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { IProduct, IWarehouseProductLot } from 'main/interfaces';
import { ProductApi, WarehouseApi } from 'src/api';
import { Formatter, sortByDate } from 'src/helpers';

const { Option } = Select;

const IS_CREATE_NEW_LOT = -9;

interface IWarehouseActionProductsFormProps {
  key: number;
  name: number;
  typeField: string;
  restField: { fieldKey?: number | undefined; };
  id_warehouse: number;
  onRemove: (index: number | number[]) => void;
}

export const WarehouseActionProductsForm: React.FC<IWarehouseActionProductsFormProps> = ({ key, name, restField, typeField, id_warehouse, onRemove }) => {
  const [products, setProducts] = useState<IProduct[] | undefined>();
  const [lots, setLots] = useState<IWarehouseProductLot[]>([]);
  const [showAddLot, setShowAddLot] = useState(false);

  const productPackagesTypes = [
    { id: 'PACK', name: 'Упаковка' },
    { id: 'RETAIL', name: 'Штука' },
  ];

  const getProducts = useCallback((value?: string) => {
    ProductApi.getAll({ page: 1, name: value }).then((res) => {
      setProducts(res?.data?.rows);
    });
  }, []);

  const productOptions = products?.map((product) => ({
    value: product.id,
    label: product?.info?.[0]
      ? product.model + ' | ' + product.info?.[0].name
      : ''
  }));

  const lotsOptions = [
      ...(typeField === 'RECEIVING' ? [{ value: IS_CREATE_NEW_LOT, label: 'Створити новий лот', description: 'Створити новий лот' }] : []),
    ...lots.sort(sortByDate<IWarehouseProductLot>('date_to', 'ASC')).map((lot) => ({
      value: lot.id,
      label: <Space>{lot.lot} <Tag>{Formatter.toDate(lot.date_to, true)}</Tag></Space>,
      description: lot.lot,
    })),
  ];

  const handleSearchProduct = useCallback((value: string): void => {
    getProducts(value);
  }, [getProducts]);

  const handleSearchLots = (id_product: string): void => {
    console.log(id_product, id_warehouse);
    if (id_warehouse && id_product) {
      WarehouseApi.getOne(String(id_warehouse), Number(id_product)).then((res) => {
        setLots(res?.data?.lots ?? []);
      });
    }
  };

  const handleChangeLot = (id_lot: number): void => {
    setShowAddLot(id_lot === IS_CREATE_NEW_LOT);
  };

  const handleRemove = (index: number | number[]): () => void => {
    return () => {
      onRemove(index);
    };
  };

  useEffect(() => {
    if (showAddLot && typeField === 'WRITE_OFF') setShowAddLot(false);
  }, [typeField, showAddLot]);

  return (
    <Row key={key} style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }} gutter={15}>
      <Col span={4}>
        <Form.Item
          {...restField}
          name={[name, 'id_product']}
          label="Продукт"
          rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
          hasFeedback
        >
          <Select
            showSearch
            placeholder=""
            filterOption={(value, option) => option?.label.includes(value) ?? false}
            onSearch={handleSearchProduct}
            options={productOptions}
            onChange={handleSearchLots}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          {...restField}
          name={[name, 'type']}
          label="Тип"
          rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
          hasFeedback
        >
          <Select placeholder="Оберіть варіант із списку">
            {productPackagesTypes.map((productPackagesType) => (<Option value={productPackagesType.id} key={productPackagesType.id}>{productPackagesType.name}</Option>))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item
          {...restField}
          name={[name, 'count']}
          label="Кількість"
          rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
          hasFeedback
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          {...restField}
          name={[name, 'id_warehouse_product_lot']}
          label="Лот"
          rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
          hasFeedback
        >
          <Select
            showSearch
            placeholder=""
            onChange={handleChangeLot}
            filterOption={(value, option) => option?.description.includes(value) ?? false}
            options={lotsOptions}
          />
        </Form.Item>
      </Col>
      {showAddLot && (
        <>
          <Col span={4}>
            <Form.Item
              {...restField}
              name={[name, 'date_to']}
              label="Термін придатності"
              rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
              hasFeedback
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              {...restField}
              name={[name, 'lot_name']}
              label="Назва лоту"
              rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
              hasFeedback
            >
              <Input/>
            </Form.Item>
          </Col>
        </>
      )}
      <Col span={3}>
        <Form.Item>
          <MinusCircleOutlined onClick={handleRemove(name)} />
        </Form.Item>
      </Col>
    </Row>
  );
};
