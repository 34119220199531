import React from 'react';
import { IOM_Info } from 'main/services/OrderManager';
import { BaseApiPostData, FormChanges } from 'src/interfaces';
import { handleFormChanges } from 'src/helpers';
import { Col, Form, Input, Row } from 'antd';

interface IOrderEditCustomerProps {
    order: IOM_Info;
    loading: boolean;
    onChange: (values: BaseApiPostData) => void;
}

export const OrderEditCustomer: React.FC<IOrderEditCustomerProps> = ({ order, onChange, loading }) => {
    const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        onChange(allValues);
    });

    return (
        <Form layout={'vertical'} onValuesChange={handleChange} initialValues={order} disabled={loading}>
            <Row gutter={30}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'customer_name'}
                               label="Імʼя"
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'customer_middle_name'}
                               label="По батькові"
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'customer_surname'}
                               label="Прізвище"
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'payment_customer_email'}
                               label="Email"
                               rules={[
                                   {
                                       required: true,
                                       type: 'email',
                                       message: 'Введіть коректну адресу',
                                   },
                               ]}
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'payment_customer_phone'}
                               label="Телефон"
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
