import React from 'react';
import { LAYOUT_BACKGROUND_COLOR } from 'src/constants';
import { Layout } from 'antd';

const AntSider = Layout.Sider;

interface ISiderProps {
    collapsed: boolean;
    children: React.ReactNode
}
export const Sider: React.FC<ISiderProps> = ({ collapsed, children }) => {
    return (
        <AntSider trigger={null} collapsible collapsed={collapsed} style={{ background: LAYOUT_BACKGROUND_COLOR }}>
            {children}
        </AntSider>
    );
};
