import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { IRouteItem } from 'src/interfaces';
import { APPLICATIONS } from 'src/apps';
import { StartPage } from '../StartPage/StartPage';
import { Result } from 'antd';

export const Router: React.FC = () => {
    const routes: IRouteItem[] = [
        {
            path: '/',
            element: StartPage,
        },
        ...APPLICATIONS.flatMap((app) => app.route),
    ];
    const router = routes.map((route) => {
        const Component = route.element;
        return (
            <Route
                key={route.path}
                path={route.path}
                element={<Component />}
            />
        );
    });

    return (
        <Routes>
            {router}
            <Route path={'*'}
                   element={(
                      <Result
                        status="404"
                        title="404"
                        subTitle="Таку сторінку не знайдено"
                        extra={<Link to={'/'}>Головна</Link>}
                      />
                    )}
            />
        </Routes>
    );
};
