import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'src/base-component';
import { Button, Space, TableProps } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { ICustomerPartnerBonus } from 'main/interfaces';
import { CustomerApi } from 'src/api';
import { usePagination } from 'src/hook';
import { BaseApiPostData, IBaseGetAllParams } from 'src/interfaces';
import { Formatter, handleChangeStatus } from 'src/helpers';
import { PartnersBonusesEditStatus } from './PartnersBonusesEditStatus';

export const PartnersBonuses: React.FC = () => {
  const [data, setData] = useState<ICustomerPartnerBonus[]>();
  const [loading, setLoading] = useState(true);
  const { pagination, handlePagination } = usePagination<ICustomerPartnerBonus>();
  const paginationParams = useMemo(() => ({ page: pagination?.current ?? 1 }), [pagination]);

  const getPartnersBonuses = useCallback((params: IBaseGetAllParams) => {
    setLoading(true);
    CustomerApi.getPartnerBonuses(params).then((res) => {
      setData(res?.data?.rows ?? []);
      handlePagination(res, getPartnersBonuses, Number(params?.page));
      setLoading(false);
    });
  }, [handlePagination]);

  const handleChangePartnerBonus = (id: number) => {
    return (values: BaseApiPostData) => {
      setLoading(true);
      CustomerApi.updatePartnerBonus(Number(id), values).then((res) => {
        handleChangeStatus<Record<string, string>>(res);
        if (res?.data?.id) getPartnersBonuses(paginationParams);
        setLoading(false);
      });
    };
  };

  const handleOpen = (link: string) => {
    return () => {
      window.open(link);
    };
  };

  const columns: TableProps<ICustomerPartnerBonus>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Партнер',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Бонус',
      dataIndex: 'bonus',
      key: 'bonus',
      render: (_, record) => Formatter.toMoney(record.bonus, true)
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <PartnersBonusesEditStatus customer_bonus={record} onChange={handleChangePartnerBonus(record.id)}/>
    },
    {
      title: 'Дії',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={handleOpen(String(record.id))}>
            <FilePdfOutlined />
          </Button>
          {/*<Button>*/}
          {/*    <DeleteOutlined />*/}
          {/*</Button>*/}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!data) {
      getPartnersBonuses(paginationParams);
    }
  }, [getPartnersBonuses, paginationParams, data]);

  return (
    <Table columns={columns} data={data ?? []} loading={loading} pagination={pagination}/>
  );
};
