import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { WarehouseApi } from 'src/api';
import { IWarehouse } from 'main/interfaces';
import { BaseApiPostData } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { WarehouseEditForm } from './WarehouseEditForm';
import { WarehouseLots } from '../';

interface IWarehouseEditProps {
  warehouse_id: string;
}

export const WarehouseEdit: React.FC<IWarehouseEditProps> = ({ warehouse_id }) => {
  const [warehouse, setWarehouse] = useState<IWarehouse>();
  const [loading, setLoading] = useState(true);

  const getWarehouse = useCallback((): void => {
    setLoading(true);
    WarehouseApi.getOne(warehouse_id).then((res) => {
      setWarehouse(res?.data);
      setLoading(false);
    });
  }, [warehouse_id]);

  const handleChangeWarehouse = useCallback((values: BaseApiPostData): void => {
    setLoading(true);
    WarehouseApi.update(Number(warehouse_id), values).then((res) => {
      handleChangeStatus<IWarehouse>(res);
      getWarehouse();
      setLoading(false);
    });
  }, [warehouse_id, getWarehouse]);

  useEffect(() => {
    if (!warehouse) {
      getWarehouse();
    }
  }, [warehouse, getWarehouse]);

  if (!warehouse) return null;

  return (
    <>
      <Divider children={'Основні'} orientation="left" orientationMargin="0"/>
      <WarehouseEditForm warehouse={warehouse} loading={loading} onChange={handleChangeWarehouse} />
      <Divider children={'Товари на складі'} orientation="left" orientationMargin="0"/>
      <WarehouseLots warehouse_id={warehouse_id} />
    </>
  );
};
