import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb } from 'antd';
import { WarehousesGrid } from '../components';

export const WarehousesPage: React.FC = () => {
  return (
    <>
      <PageHeader title={'Склади'}
                  subtitle={'Перегляд складів компанії'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Склади
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
      />
      <WarehousesGrid/>
    </>
  );
};
