import { IEntryPoint, IRouteItem } from 'src/interfaces';
import { ROUTES } from 'src/constants';
import { CustomerEditPage, CustomersPage, CustomerAddPage } from './pages';

const routeList: IRouteItem[] = [
  {
    path: ROUTES.CUSTOMERS,
    exact: true,
    element: CustomersPage,
  },
  {
    path: ROUTES.CUSTOMERS_EDIT,
    exact: true,
    element: CustomerEditPage,
  },
  {
    path: ROUTES.CUSTOMERS_ADD,
    exact: true,
    element: CustomerAddPage,
  },
];

export const CustomerApp: IEntryPoint = {
  route: routeList,
};
