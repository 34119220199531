import React, { useCallback, useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { IAttributeValue, IAttributeValueInfo } from 'main/interfaces';
import { AttributeApi } from 'src/api';
import { ApiBaseResponse, FormChanges } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { AttributeItemForm, AttributeItems } from '../';

interface IAttributeInfoTabProps {
    attribute_id: string;
}

export const AttributeInfoTab: React.FC<IAttributeInfoTabProps> = ({ attribute_id }) => {
    const [attributeItems, setAttributeItems] = useState<IAttributeValue[] | undefined>();

    const getAttributesItems = useCallback(() => {
        AttributeApi.getValues(Number(attribute_id)).then((res) => {
            setAttributeItems(res?.data);
        });
    }, [attribute_id]);

    const handleDeleteAttributeValue = useCallback(async (product_attribute_id: number): Promise<ApiBaseResponse<IAttributeValue> | undefined> => {
        const response = await AttributeApi.deleteValue(product_attribute_id);
        handleChangeStatus<IAttributeValue>(response);
        getAttributesItems();
        return response;
    }, [getAttributesItems]);

    const handleCreateAttributeValue = useCallback(async (changedValues: FormChanges): Promise<ApiBaseResponse<IAttributeValue> | undefined> => {
        const response = await AttributeApi.addValue(Number(attribute_id), { ...changedValues }).then();
        handleChangeStatus<IAttributeValue>(response);
        getAttributesItems();
        return response;
    }, [attribute_id, getAttributesItems]);

    const handleCreateAttributeValueInfo = useCallback(async (attribute_value_id: number, changedValues: FormChanges, language: number): Promise<ApiBaseResponse<IAttributeValueInfo> | undefined> => {
        const response = await AttributeApi.createValueInfo(Number(attribute_value_id), { ...changedValues, id_language: language }).then();
        handleChangeStatus<IAttributeValueInfo>(response);
        getAttributesItems();
        return response;
    }, [getAttributesItems]);

    const handleUpdateAttributeValueInfo = useCallback(async (attribute_value_id: number, changedValues: FormChanges, language: number): Promise<ApiBaseResponse<IAttributeValueInfo> | undefined> => {
        const response = await AttributeApi.updateValueInfo(Number(attribute_value_id), { ...changedValues, id_language: language }).then();
        handleChangeStatus<IAttributeValueInfo>(response);
        return response;
    }, []);

    useEffect(() => {
        if (!attributeItems) {
            getAttributesItems();
        }
    }, [attributeItems, getAttributesItems]);

    return (
        <Row gutter={31}>
            <Col xs={24} sm={24} md={24} lg={9}>
                {attributeItems && (
                    <>
                        <Divider children={'Список створених варіантів'} orientation="left" orientationMargin="0"/>
                        <AttributeItems items={attributeItems}
                                        onDelete={handleDeleteAttributeValue}
                                        onCreate={handleCreateAttributeValueInfo}
                                        onUpdate={handleUpdateAttributeValueInfo}
                        />
                    </>
                )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={9}>
                <Divider children={'Додати варіант до атрибуту'} orientation="left" orientationMargin="0"/>
                <AttributeItemForm onCreate={handleCreateAttributeValue} />
            </Col>
        </Row>
    );
};
