export enum ROUTES {
    CATALOG_PRODUCTS = '/products',
    CATALOG_PRODUCTS_ADD = '/products/add',
    CATALOG_PRODUCTS_EDIT = '/products/edit/:id',
    CATALOG_CATEGORIES = '/categories',
    CATALOG_CATEGORIES_ADD = '/categories/add',
    CATALOG_CATEGORIES_EDIT = '/categories/edit/:id',
    CATALOG_ATTRIBUTES = '/attributes',
    CATALOG_ATTRIBUTES_ADD = '/attributes/add',
    CATALOG_ATTRIBUTES_EDIT = '/attributes/edit/:id',
    CATALOG_TAG = '/tag',
    CATALOG_TAG_ADD = '/tag/add',
    CATALOG_TAG_EDIT = '/tag/edit/:id',
    CUSTOMERS = '/customers',
    CUSTOMERS_EDIT = '/customers/edit/:id',
    CUSTOMERS_ADD = '/customers/add',
    SETTINGS_RELATED_PRODUCTS = '/settings/related-products',
    ECONOMICAL_PARTNER_BONUS = '/economical/partner-bonuses',
    WAREHOUSES = '/warehouses',
    WAREHOUSES_EDIT = '/warehouses/edit/:id',
    WAREHOUSES_ACTIONS = '/warehouses/actions',
    WAREHOUSES_ACTIONS_VIEW = '/warehouses/actions/:id',
    WAREHOUSES_ACTIONS_ADD = '/warehouses/actions/add',
    ORDERS = '/orders',
    ORDERS_EDIT = '/orders/edit/:id',
    ORDERS_VIEWS = '/orders/views/:id',
    ORDERS_ADD = '/orders/add',
};

