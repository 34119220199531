import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { Table as AntTable, TablePaginationConfig, TableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { ApiBaseResponse, ApiWithPaginationResponse, IBaseGetAllParams, IFilter } from 'src/interfaces';
import { Filters } from 'src/base-component';
import { IOM_Order } from 'main/services/OrderManager';

interface ITableProps<T> {
  getColumns: (getData: (params?: Record<string, string | number | boolean | undefined>) => void) => TableProps<IOM_Order>['columns']
  onExpandable?: (record: T) => ReactNode;
  getData: (params: IBaseGetAllParams) => Promise<ApiBaseResponse<ApiWithPaginationResponse<T>> | undefined>;
  filtersTypes?: IFilter[];
}

export const Table = <T, >({ getColumns, getData, filtersTypes, onExpandable }: ITableProps<T>): ReactElement<ITableProps<T>> => {
  const [data, setData] = useState<T[]>();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<TablePaginationConfig | undefined>();
  const [filters, setFilters] = useState<IBaseGetAllParams>({});

  const handleData = useCallback((params?: Record<string, string | number | boolean | undefined>): void => {
    const requestParams = {
      page: pagination?.current ?? 1,
      limit: pagination?.pageSize ?? 50,
      ...(params?.clear_filters ? {} : filters),
      ...(params?.clear_filters ? {} : (params ?? {})),
    };
    setLoading(true);
    getData(requestParams).then((res) => {
      setData(res?.data?.rows);
      setLoading(false);
      setPagination({
        pageSize: res?.data?.limit,
        total: res?.data?.count,
        current: res?.data?.page,
      });
    });
  }, [getData, pagination, filters]);

  const columns = getColumns(handleData);

  const handlePagination = (page: number, pageSize: number): void => {
    handleData({ page, limit: pageSize });
  };

  const handleFilter = useCallback((filters?: Record<string, string | number | boolean | undefined>) => {
    if (!filters?.clear_filters) setFilters(filters);
    handleData(filters);
  }, [handleData]);

  useEffect(() => {
    if (!data) handleData();
  }, [data, handleData, getData]);

  return (
    <>
      {filtersTypes && <Filters filters={filtersTypes} onChange={handleFilter} loading={loading} />}
      <div className={'mb-table'}>
        <AntTable columns={columns as AnyObject[]}
                  dataSource={data as AnyObject[]}
                  loading={loading}
                  pagination={{ ...pagination, onChange: handlePagination }}
                  expandable={{
                    expandedRowRender: onExpandable,
                    rowExpandable: (record) => !!onExpandable,
                  }}
        />
      </div>
    </>
  );
};
