import React from 'react';
import {
    Button,
    Form,
    Input,
    Space,
} from 'antd';
import { IAttributeValue } from 'main/interfaces';
import { ApiBaseResponse, FormChanges } from 'src/interfaces';

interface IAttributeItemFormProps {
    onCreate: (changedValues: FormChanges) => Promise<ApiBaseResponse<IAttributeValue> | undefined>
}

export const AttributeItemForm: React.FC<IAttributeItemFormProps> = ({ onCreate }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleFinish = (values: Record<string, string | boolean | number>): void => {
        setLoading(true);
        onCreate(values).then((res) => {
            if (res?.status) form.resetFields();
            setLoading(false);
        });
    };

    return (
        <Form
            name="validate_other"
            onFinish={handleFinish}
            form={form}
            disabled={loading}
            layout="vertical"
            style={{ maxWidth: 900 }}
        >
            <Form.Item name={'name'}
                       label="Назва"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Створити
                    </Button>
                    <Button htmlType="reset">Скинути</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
