import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { ProductGeneralInfoForm, ProductMainInfoForm } from '../';
import { IManufacturer, IProduct, IProductInfo } from 'main/interfaces';
import { ManufacturerApi, ProductApi } from 'src/api';
import { ApiBaseResponse, FormChanges } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';

interface IProductMainTabProps {
    product_id: string;
}

export const ProductMainTab: React.FC<IProductMainTabProps> = ({ product_id }) => {
    const [product, setProduct] = useState<IProduct | undefined>();
    const [productInfo, setProductInfo] = useState<IProductInfo[] | undefined>();
    const [manufacturers, setManufacturers] = React.useState<IManufacturer[] | undefined>();

    const getProductInfo = useCallback((): void => {
        ProductApi.getInfo(product_id).then((res) => {
            setProductInfo(res?.data);
        });
    }, [product_id]);

    const handleUpdateProduct = useCallback(async (changedValues: FormChanges): Promise<ApiBaseResponse<IProduct> | undefined> => {
        const response = await ProductApi.update(Number(product_id), changedValues);
        handleChangeStatus<IProduct>(response);
        return response;
    }, [product_id]);

    const handleUpdateProductInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<IProduct> | undefined> => {
        const response = await ProductApi.updateInfo(Number(product_id), { ...changedValues, id_language: language });
        handleChangeStatus<IProduct>(response);
        return response;
    }, [product_id]);

    const handleCreateProductInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<IProduct> | undefined> => {
        const response = await ProductApi.createInfo(Number(product_id), {
            ...changedValues,
            id_language: language
        }).then();
        handleChangeStatus<IProduct>(response);
        getProductInfo();
        return response;
    }, [getProductInfo, product_id]);

    useEffect(() => {
        if (!product) {
            ProductApi.getOne(product_id).then((res) => {
                setProduct(res?.data);
            });
        }
    }, [product_id, product]);

    useEffect(() => {
        if (!productInfo) {
            getProductInfo();
        }
    }, [getProductInfo, product_id, productInfo]);

    useEffect(() => {
        if (!manufacturers) {
            ManufacturerApi.getAll().then((res) => {
                setManufacturers(res?.data?.rows ?? []);
            });
        }
    }, [manufacturers]);

    return (
        <>
            <Divider children={'Назва та опис'} orientation="left" orientationMargin="0"/>
            {productInfo && (
                <ProductMainInfoForm info={productInfo}
                                     onCreate={handleCreateProductInfo}
                                     onUpdate={handleUpdateProductInfo}
                />
            )}
            {product && manufacturers && (
                <>
                    <Divider children={'Основна інформація'} orientation="left" orientationMargin="0"/>
                    <ProductGeneralInfoForm product={product}
                                            onUpdate={handleUpdateProduct}
                                            manufacturers={manufacturers}
                    />
                </>
            )}
        </>
    );
};
