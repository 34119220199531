import React from 'react';
import {
    Button,
    Form,
    Input, InputNumber,
    Space,
} from 'antd';
import { AttributeApi } from 'src/api';
import { handleChangeStatus } from 'src/helpers';
import { IAttribute } from 'main/interfaces';

export const AttributeAddForm: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleFinish = (values: Record<string, string | boolean | number>): void => {
        setLoading(true);
        AttributeApi.create(values).then((res) => {
            handleChangeStatus<IAttribute>(res);
            if (res?.status) form.resetFields();
            setLoading(false);
        });
    };

    return (
        <Form
            name="validate_other"
            onFinish={handleFinish}
            form={form}
            labelCol={{ span: 6 }}
            disabled={loading}
            wrapperCol={{ span: 14 }}
            initialValues={{
                sort: 1,
            }}
            style={{ maxWidth: 900 }}
        >
            <Form.Item name={'name'}
                       label="Назва атрибуту"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item name={'sort'}
                       label="Порядок сортування"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <InputNumber />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Створити
                    </Button>
                    <Button htmlType="reset">Скинути</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
