import React, { useContext } from 'react';
import { Avatar, Button, Popover, Space, Typography } from 'antd';
import { PRIMARY_COLOR } from 'src/constants';
import { DownOutlined } from '@ant-design/icons';
import { AuthContext } from 'src/apps';
import { IUserInfo } from 'main/interfaces';
import { useCookies } from 'react-cookie';

export const User: React.FC = () => {
  const [,, removeCookie] = useCookies(['AuthToken']);
  const auth = useContext(AuthContext);

  const getUserInfo = (key: keyof IUserInfo): string | undefined => {
    return auth?.user?.info?.[0] ? String(auth?.user?.info?.[0]?.[key]) : '';
  };

  const full_name = `${getUserInfo('surname')} ${getUserInfo('name')}`;
  const initials = `${getUserInfo('surname')?.slice(0, 1).toUpperCase()}${getUserInfo('name')?.slice(0, 1).toUpperCase()}`;

  const handleRemoveUser = (): void => {
    removeCookie('AuthToken');
    auth?.setUser(null);
  };

  return (
    <Popover content={<Button onClick={handleRemoveUser}>Вийти</Button>} trigger="click">
      <Space>
        <DownOutlined />
        <Typography.Text>{full_name}</Typography.Text>
        <Avatar style={{ backgroundColor: PRIMARY_COLOR, verticalAlign: 'middle', color: '#ffffff' }} gap={1}>
          {initials}
        </Avatar>
      </Space>
    </Popover>
  );
};
