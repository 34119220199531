import React, { useState } from 'react';
import { Switch } from 'antd';
import { BaseApiPostData } from 'src/interfaces';
import { ICustomerPartnerBonus } from 'main/interfaces';

interface IPartnersBonusesEditStatusProps {
  customer_bonus: ICustomerPartnerBonus;
  onChange: (customer_bonus: BaseApiPostData) => void;
}

export const PartnersBonusesEditStatus: React.FC<IPartnersBonusesEditStatusProps> = ({ customer_bonus, onChange }) => {
  const [status, setStatus] = useState<number>(customer_bonus.status);

  const handleChangeStatus = (checked: boolean): void => {
    checked && setStatus(checked ? 1 : 0);
    onChange({ status: checked });
  };

  return <Switch value={Boolean(status)} onChange={handleChangeStatus} />;
};
