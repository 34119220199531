import React, { useEffect } from 'react';
import {
    Button,
    Form,
    InputNumber,
    Input,
    Select,
    Space,
    Switch,
} from 'antd';
import { PRODUCT_FIELDS } from 'src/interfaces';
import { ManufacturerApi, ProductApi } from 'src/api';
import { handleChangeStatus, makeTransliteration } from 'src/helpers';
import { IManufacturer, IProduct } from 'main/interfaces';

const { Option } = Select;
export const ProductAddForm: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [manufacturers, setManufacturers] = React.useState<IManufacturer[] | undefined>();

    const nameField = Form.useWatch('name', form);
    const priceField = Form.useWatch('price', form);
    const inPackField = Form.useWatch('in_pack', form);

    const handleFinish = (values: Record<string, string | boolean | number>): void => {
        setLoading(true);
        ProductApi.create(values).then((res) => {
            handleChangeStatus<IProduct>(res);
            if (res?.status) form.resetFields();
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!manufacturers) {
            setLoading(true);
            ManufacturerApi.getAll().then((res) => {
                setManufacturers(res?.data?.rows ?? []);
                setLoading(false);
            });
        }
    }, [manufacturers]);

    useEffect(() => {
        form.setFieldValue('alias', makeTransliteration(nameField));
    }, [form, nameField]);

    useEffect(() => {
        priceField && inPackField && form.setFieldValue('price_one', (priceField / inPackField).toFixed(2));
    }, [form, priceField, inPackField]);

    return (
        <Form
            name="validate_other"
            onFinish={handleFinish}
            form={form}
            labelCol={{ span: 6 }}
            disabled={loading}
            wrapperCol={{ span: 14 }}
            initialValues={{
                is_new: false,
                is_active: false,
                is_preorder: false,
                partner_access: true,
            }}
            style={{ maxWidth: 900 }}
        >
            <Form.Item name="name"
                       label="Назва"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.MODEL}
                       label="Модель"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.ALIAS}
                       label="Аліас"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={PRODUCT_FIELDS.ID_MANUFACTURER}
                label="Виробник"
                hasFeedback
                rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
            >
                <Select placeholder="Оберіть варіант із списку">
                    {manufacturers?.map((manufacturer) => (<Option value={manufacturer.id} key={manufacturer.id}>{manufacturer.name}</Option>))}
                </Select>
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.PRICE}
                       label="Ціна"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <InputNumber addonAfter={'€'}/>
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.IN_PACK}
                       label="Кількість в упаковці"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <InputNumber addonAfter={'шт'}/>
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.PRICE_ONE}
                       label="Ціна за одиницю"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <InputNumber addonAfter={'€'}/>
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.VAT}
                       label="Податок"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <InputNumber addonAfter={'%'}/>
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.IS_PREORDER}
                       label="Для передзамовлення"
            >
                <Switch />
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.PARTNER_ACCESS}
                       label="Для партнерської програми"
            >
                <Switch />
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.IS_ACTIVE}
                       label="Активний"
            >
                <Switch />
            </Form.Item>
            <Form.Item name={PRODUCT_FIELDS.IS_NEW}
                       label="Новинка"
            >
                <Switch />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Створити
                    </Button>
                    <Button htmlType="reset">Скинути</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
