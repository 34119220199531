import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { AttributeGeneralInfoForm, AttributeMainInfoForm } from '../';
import { IAttribute, IAttributeInfo } from 'main/interfaces';
import { AttributeApi } from 'src/api';
import { ApiBaseResponse, FormChanges } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';

interface IProductMainTabProps {
    attribute_id: string;
}

export const AttributeMainTab: React.FC<IProductMainTabProps> = ({ attribute_id }) => {
    const [attribute, setAttribute] = useState<IAttribute | undefined>();

    const getAttribute = useCallback(() => {
        AttributeApi.getOne(attribute_id).then((res) => {
            setAttribute(res?.data);
        });
    }, [attribute_id]);

    const handleUpdateAttribute = useCallback(async (changedValues: FormChanges): Promise<ApiBaseResponse<IAttribute> | undefined> => {
        const response = await AttributeApi.update(Number(attribute_id), changedValues);
        handleChangeStatus<IAttribute>(response);
        return response;
    }, [attribute_id]);

    const handleUpdateAttributeInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<IAttributeInfo> | undefined> => {
        const response = await AttributeApi.updateInfo(Number(attribute_id), { ...changedValues, id_language: language });
        handleChangeStatus<IAttributeInfo>(response);
        return response;
    }, [attribute_id]);

    const handleCreateAttributeInfo = useCallback(async (changedValues: FormChanges, language: number): Promise<ApiBaseResponse<IAttributeInfo> | undefined> => {
        const response = await AttributeApi.createInfo(Number(attribute_id), { ...changedValues, id_language: language }).then();
        handleChangeStatus<IAttributeInfo>(response);
        getAttribute();
        return response;
    }, [getAttribute, attribute_id]);

    useEffect(() => {
        if (!attribute) {
            getAttribute();
        }
    }, [getAttribute, attribute_id, attribute]);

    return (
        <>
            <Divider children={'Назва'} orientation="left" orientationMargin="0"/>
            {attribute && (
                <AttributeMainInfoForm attribute={attribute}
                                     onCreate={handleCreateAttributeInfo}
                                     onUpdate={handleUpdateAttributeInfo}
                />
            )}
            {attribute && (
                <>
                    <Divider children={'Основна інформація'} orientation="left" orientationMargin="0"/>
                    <AttributeGeneralInfoForm attribute={attribute}
                                            onUpdate={handleUpdateAttribute}
                    />
                </>
            )}
        </>
    );
};
