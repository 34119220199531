import { IEntryPoint, IRouteItem } from 'src/interfaces';
import { ROUTES } from 'src/constants';
import {
  PartnersBonusesPage,
} from './pages';

const routeList: IRouteItem[] = [
  {
    path: ROUTES.ECONOMICAL_PARTNER_BONUS,
    exact: true,
    element: PartnersBonusesPage,
  },
];

export const EconomicalApp: IEntryPoint = {
  route: routeList,
};
