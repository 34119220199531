import _, { DebouncedFunc } from 'lodash';
import { notification } from 'antd';
import { ApiBaseResponse } from 'src/interfaces';

type FormData = Record<string, string | number | boolean>;

export const handleFormChanges = (callback: (changedValues: FormData, allValues: FormData) => void): DebouncedFunc<(changedValues: FormData, allValues: FormData) => void> => {
    return _.debounce((changedValues: FormData, allValues: FormData) => {
        callback(changedValues, allValues);
    }, 1000);
};

export const handleTextEditorChange = (callback: (content: string) => void): DebouncedFunc<(content: string) => void> => {
    return _.debounce((content) => {
        callback(content);
    }, 1000);
};

export const handleChangeStatus = <T>(res: ApiBaseResponse<T> | undefined): void => {
    if (res?.status) {
        notification.success({
            message: 'Зміни успішно збережені',
            description: '',
        });
    }
};
