import React from 'react';
import { BaseApiPostData, FormChanges } from 'src/interfaces';
import { handleFormChanges } from 'src/helpers';
import { Col, Form, Input, Row } from 'antd';
import { IWarehouse } from 'main/interfaces';

interface IWarehouseEditFormProps {
  warehouse: IWarehouse;
  loading: boolean;
  onChange: (values: BaseApiPostData) => void;
}

export const WarehouseEditForm: React.FC<IWarehouseEditFormProps> = ({ warehouse, loading, onChange }) => {
  const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
    onChange(allValues);
  });

  return (
    <Form layout={'vertical'} onValuesChange={handleChange} initialValues={warehouse} disabled={loading}>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'name'}
                     label="Назва"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name={'type'}
                     label="Тип"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Input disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
