import React from 'react';
import { withLanguageTabs } from 'src/hoc';
import { IProductInfo } from 'main/interfaces';
import { getProductInfoByLang, handleFormChanges } from 'src/helpers';
import { Col, Form, Input, Row } from 'antd';
import { FormChanges } from 'src/interfaces';

const { TextArea } = Input;

interface IProductSeoFormProps {
    language?: number;
    info?: IProductInfo[];
    onUpdate: (changes: FormChanges, language: number) => void;
}

export const ProductSeoForm: React.FC<IProductSeoFormProps> = ({ language, info, onUpdate }) => {
    const productInfo = getProductInfoByLang(info, language);
    const disabledField = !productInfo;
    const initialValues = {
        meta_title: productInfo?.meta_title,
        meta_keywords: productInfo?.meta_keywords,
        meta_description: productInfo?.meta_description,
    };

    const tooltipDisabled = disabledField && 'Для можливості редагування потрібно заповнити поле "Назва" в "Основних даних" для даної мови';

    const sendUpdates = (changes: Record<string, string | number | boolean>): void => {
        if (language) {
            if (productInfo) {
                onUpdate(changes, language);
            }
        }
    };

    const handleChangeForm = handleFormChanges((changedValues: Record<string, string | number | boolean>) => {
        sendUpdates(changedValues);
    });

    return initialValues ? (
        <Form layout={'vertical'} initialValues={initialValues} onValuesChange={handleChangeForm}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="meta_title"
                               label="SEO: Meta-Title"
                               tooltip={tooltipDisabled}
                               rules={[]}
                               hasFeedback
                    >
                        <Input disabled={disabledField}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="meta_keywords"
                               label="SEO: Meta-Keywords"
                               tooltip={tooltipDisabled}
                               rules={[]}
                               hasFeedback
                    >
                        <Input disabled={disabledField}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="meta_description"
                               label="SEO: Meta-Description"
                               tooltip={tooltipDisabled}
                               rules={[]}
                               hasFeedback
                    >
                        <TextArea rows={9} disabled={disabledField}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    ) : null;
};

export const productSeoForm = withLanguageTabs<IProductSeoFormProps>(ProductSeoForm);
