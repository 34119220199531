import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb } from 'antd';
import { WarehouseActionForm } from '../components';
import { ROUTES } from 'src/constants';

export const WarehouseAddActionPage: React.FC = () => {
  return (
    <>
      <PageHeader title={'Створення руху товару'}
                  subtitle={'Створення надходження або списання товарів'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <a href={ROUTES.WAREHOUSES_ACTIONS}>Рух товарів</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Створення руху товарів
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
      />
      <WarehouseActionForm />
    </>
  );
};
