import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'src/base-component';
import { Button, Space, TableProps } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ITag } from 'main/interfaces';
import { TagApi } from 'src/api';
import { ROUTES } from 'src/constants';
import { usePagination } from 'src/hook';
import { IBaseGetAllParams } from 'src/interfaces';

export const TagGrid: React.FC = () => {
    const [data, setData] = useState<ITag[]>();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { pagination, handlePagination } = usePagination<ITag>();
    const paginationParams = useMemo(() => ({ page: pagination?.current ?? 1 }), [pagination]);

    const getTags = useCallback((params: IBaseGetAllParams): void => {
        setLoading(true);
        TagApi.getAll(params).then((res) => {
            setData(res?.data?.rows ?? []);
            handlePagination(res, getTags, Number(params?.page));
            setLoading(false);
        });
    }, [handlePagination]);

    const handleEdit = (id: number): () => void => {
        return () => navigate(ROUTES.CATALOG_TAG + '/edit/' + id);
    };
    const columns: TableProps<ITag>['columns'] = [
        {
            title: 'Назва',
            // dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                if (record?.info) {
                    return record.info[0].value;
                }
                return null;
            }
        },
        {
            title: 'Аліас',
            dataIndex: 'alias',
            key: 'alias',
        },
        {
            title: 'Дії',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={handleEdit(record.id)}>
                        <EditOutlined />
                    </Button>
                    <Button>
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (!data) {
            getTags(paginationParams);
        }
    }, [getTags, paginationParams, data]);

    return (
        <Table columns={columns} data={data ?? []} loading={loading} pagination={pagination}/>
    );
};
