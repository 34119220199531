import React from 'react';
import { LAYOUT_BACKGROUND_COLOR } from 'src/constants';
import { Button, Col, Layout as AntLayout, Row } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { User } from '..';

const AntHeader = AntLayout.Header;

interface IHeaderProps {
    collapsed: boolean;
    onCollapsed: () => void;
}

export const Header: React.FC<IHeaderProps> = ({ collapsed, onCollapsed }) => {
    const handleCollapse = (): void => {
        onCollapsed();
    };

    return (
        <AntHeader style={{ background: LAYOUT_BACKGROUND_COLOR }}>
            <Row justify={'end'}>
              <Col span={21}>
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={handleCollapse}
                />
              </Col>
              <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <User/>
              </Col>
            </Row>
        </AntHeader>
    );
};
