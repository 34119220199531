import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'src/base-component';
import { TableProps } from 'antd';
import { WarehouseApi } from 'src/api';
import { IWarehouseActionProduct } from 'main/interfaces';
import { ROUTES } from 'src/constants';

interface IWarehouseActionProductsProps {
  warehouse_action_id: number;
}

export const WarehouseActionsProducts: React.FC<IWarehouseActionProductsProps> = ({ warehouse_action_id }) => {
  const [data, setData] = useState<IWarehouseActionProduct[]>([]);
  const [loading, setLoading] = useState(true);

  const getActions = useCallback((): void => {
    setLoading(true);
    WarehouseApi.getActionProducts(warehouse_action_id).then((res) => {
      setData(res?.data ?? []);
      setLoading(false);
    });
  }, [warehouse_action_id]);

  const columns: TableProps<IWarehouseActionProduct>['columns'] = [
    {
      title: 'Назва товару',
      key: 'product_name',
      render: (_, record) => <a href={ROUTES.CATALOG_PRODUCTS + '/edit/' + record.id_product}>{record?.product?.info ? record?.product?.info[0]?.name : ''}</a>
    },
    {
      title: 'Модель',
      key: 'product_model',
      render: (_, record) => record?.product?.model ?? '',

    },
    {
      title: 'Тип',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Склад',
      key: 'warehouse_name',
      render: (_, record) => record?.warehouse?.name ?? '',
    },
    {
      title: 'Лот',
      key: 'lot',
      render: (_, record) => record?.warehouse_product_lot?.lot ?? '',

    },
    {
      title: 'Кількість',
      dataIndex: 'count',
      key: 'count',
    },
  ];

  useEffect(() => {
    getActions();
  }, [getActions]);

  return (
    <Table columns={columns} data={data} loading={loading} />
  );
};
