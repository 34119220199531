import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'src/base-component';
import { Button, Space, TableProps } from 'antd';
import { FolderViewOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { WarehouseApi } from 'src/api';
import { ROUTES } from 'src/constants';
import { IWarehouse } from 'main/interfaces';

export const WarehousesGrid: React.FC = () => {
  const [data, setData] = useState<IWarehouse[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getWarehouses = useCallback((page?: number): void => {
    setLoading(true);
    WarehouseApi.getAll({ type: 'STORE,INFO' }).then((res) => {
      setData(res?.data?.rows ?? []);
      setLoading(false);
    });
  }, []);

  const handleEdit = (id: number): () => void => {
    return () => navigate(ROUTES.WAREHOUSES + '/edit/' + id);
  };

  const columns: TableProps<IWarehouse>['columns'] = [
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Дії',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={handleEdit(record.id)}>
            <FolderViewOutlined/>
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getWarehouses();
  }, [getWarehouses]);

  return (
    <Table columns={columns} data={data} loading={loading} />
  );
};
