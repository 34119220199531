import React, { useMemo } from 'react';
import { IOM_OrderHistoryChange } from 'main/services/OrderManager';
import { Space, TableProps, Typography } from 'antd';
import { Formatter, sortByDate } from 'src/helpers';
import { Table } from 'src/base-component';
import { OrderStatus } from '../';

const { Title } = Typography;

interface IOrderHistoriesProps {
    history_change: IOM_OrderHistoryChange[];
}

export const OrderHistories: React.FC<IOrderHistoriesProps> = ({ history_change }) => {
    const histories = useMemo(() => {
        return history_change.sort(sortByDate<IOM_OrderHistoryChange>('createdAt', 'DESC'));
    }, [history_change]);

    const columns: TableProps<IOM_OrderHistoryChange>['columns'] = [
        {
            title: 'Статус',
            dataIndex: 'id_status',
            key: 'id_status',
            render: (_, record) => (<OrderStatus id={record.id_status} name={record.status_name}/>)
        },
        {
            title: 'Менеджер',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Коментар',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Дата',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => Formatter.toDate(record.createdAt),
        },
    ];

    return (
        <>
            <Title level={5}>Перелік змін</Title>
            <Space/>
            <Table columns={columns} data={histories} loading={false}/>
        </>
    );
};
