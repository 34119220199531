import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderDetail } from '../components';
import { ROUTES } from 'src/constants';

export const OrderDetailPage: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const handleEdit = (): void => {
        navigate(ROUTES.ORDERS + '/edit/' + id);
    };

    return (
        <>
            <PageHeader title={'Замовлення #' + id}
                        subtitle={'Детальний перегляд замовлення'}
                        breadcrumbs={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <a href="/">Головна</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href={ROUTES.ORDERS}>Замовлення</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Замовлення №{id}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        }
                        extra={[
                            <Button key="1" type="primary" onClick={handleEdit}>
                                <EditOutlined /> Редагувати
                            </Button>
                        ]}
            />
            {id && <OrderDetail order_id={id}/>}
        </>
    );
};
