import React, { useEffect } from 'react';
import { Card, Space } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export const InProgress: React.FC = () => {
  const [isShow, setIsShow] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShow(true);
    }, 5009);
  }, []);

  return (
    <Card title={<Space><WarningOutlined style={{ color: 'red' }}/>Упс! Функціонал знаходиться в розробці</Space>} bordered style={{ width: 500 }}>
      {isShow && <img src="/in_progress.jpg" alt="Функціонал в роботі" style={{ width: '100%' }} />}
    </Card>
  );
};
