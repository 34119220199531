import React from 'react';
import { Breadcrumb } from 'antd';
import { PageHeader } from 'src/base-component';
import { ROUTES } from 'src/constants';
import { CustomerAdd } from '../components';

export const CustomerAddPage: React.FC = () => {
  return (
    <>
      <PageHeader title={'Створення клієнта'}
                  subtitle={'Заповніть форму щоб створити клієнта'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <a href={ROUTES.CUSTOMERS}>Клієнти</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Створення клієнта
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
      />
      <CustomerAdd/>
    </>
  );
};
