import React, { SyntheticEvent, useState } from 'react';
import { InputNumber } from 'antd';
import { BaseApiPostData } from 'src/interfaces';
import { IOM_OrderProduct } from 'main/services/OrderManager';

interface IOrderEditProductsCountProps {
  product: IOM_OrderProduct;
  onChange: (product: BaseApiPostData) => void;
}

export const OrderEditProductsCount: React.FC<IOrderEditProductsCountProps> = ({ product, onChange }) => {
  const [count, setCount] = useState<number>(product.count);

  const handleChangeCount = (value: number | null): void => {
    setCount(value ?? 0);
  };

  const handleChaneProduct = (event: SyntheticEvent<HTMLInputElement>): void => {
    onChange({ id: product.id, price: product.price, count: event.currentTarget.value ?? 0 });
  };

  return <InputNumber value={count} onBlur={handleChaneProduct} onChange={handleChangeCount} addonAfter={'шт.'} />;
};
