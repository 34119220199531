import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb } from 'antd';
import { PartnersBonuses } from '../components';

export const PartnersBonusesPage: React.FC = () => {
  return (
    <>
      <PageHeader title={'Бонуси партнерів'}
                  subtitle={'Управління виплатами бонусів партнерам'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Бонуси партнерів
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
      />
      <PartnersBonuses/>
    </>
  );
};
