import React from 'react';
import { ConfigProvider } from 'antd';
import { PRIMARY_COLOR } from 'src/constants';

interface IThemeConfigProps {
    children?: React.ReactNode;
}

export const ThemeConfig: React.FC<IThemeConfigProps> = ({ children }) => {
    const config = {
        token: {
            colorPrimary: PRIMARY_COLOR,
            colorFillSecondary: PRIMARY_COLOR,
            colorLink: PRIMARY_COLOR,
            // colorTextBase: PRIMARY_COLOR, /// Основний текст
        },
    };

    return (
        <ConfigProvider theme={config}>
            {children}
        </ConfigProvider>
    );
};
