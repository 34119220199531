import React, { useEffect } from 'react';
import { Button, Form, Input, Select, Space } from 'antd';
import { IWarehouse } from 'main/interfaces';
import { WarehouseApi } from 'src/api';
import { handleChangeStatus } from 'src/helpers';
import { PlusOutlined } from '@ant-design/icons';
import {
  WarehouseActionProductsForm
} from './WarehouseActionProductsForm';

const { Option } = Select;
const { TextArea } = Input;

export const WarehouseActionForm: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [warehouses, setWarehouses] = React.useState<IWarehouse[] | undefined>();
  const warehouseField = Form.useWatch('id_warehouse', form);
  const typeField = Form.useWatch('type', form);

  const actionsTypes = [
    { id: 'RECEIVING', name: 'Надходження' },
    { id: 'WRITE_OFF', name: 'Списання' }
  ];

  const handleReceiving = (values: Record<string, string | boolean | number | Record<string, string | number | boolean | null>[]>): void => {
    WarehouseApi.receiving(values).then((res) => {
      handleChangeStatus<Record<string, string>>(res);
      if (res?.status) form.resetFields();
      setLoading(false);
    });
  };

  const handleWriteOff = (values: Record<string, string | boolean | number | Record<string, string | number | boolean | null>[]>): void => {
    WarehouseApi.write_off(values).then((res) => {
      handleChangeStatus<Record<string, string>>(res);
      if (res?.status) form.resetFields();
      setLoading(false);
    });
  };

  const handleFinish = (values: Record<string, string | boolean | number | Record<string, string | number | boolean>[]>): void => {
    setLoading(true);
    const mappedValues = {
      ...values,
      products: (values.products as Record<string, string | number | boolean>[]).map((product) => (
        {
          ...product,
          id_warehouse_product_lot: product?.id_warehouse_product_lot === -9 ? null : product?.id_warehouse_product_lot
        })
      )
    };
    values?.type === 'RECEIVING' ? handleReceiving(mappedValues) : handleWriteOff(mappedValues);
    console.log(values);
  };

  useEffect(() => {
    if (!warehouses) {
      setLoading(true);
      WarehouseApi.getAll({ type: 'STORE,INFO' }).then((res) => {
        setWarehouses(res?.data?.rows ?? []);
        setLoading(false);
      });
    }
  }, [warehouses]);

  useEffect(() => {
    if (typeField) form.setFieldValue('products', []);
  }, [typeField, form]);

  if (!warehouses) return null;

  return (
    <Form
      name="warehouse_action_form"
      onFinish={handleFinish}
      form={form}
      layout={'vertical'}
      disabled={loading}
      style={{ maxWidth: 1300 }}
    >
      <Form.Item name="type"
                 label="Тип"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Select placeholder="Оберіть варіант із списку">
          {actionsTypes.map((actionType) => (
            <Option value={actionType.id} key={actionType.id}>{actionType.name}</Option>))}
        </Select>
      </Form.Item>
      <Form.Item name="id_warehouse"
                 label="Склад"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Select placeholder="Оберіть варіант із списку">
          {warehouses.map((warehouse) => (<Option value={warehouse.id} key={warehouse.id}>{warehouse.name}</Option>))}
        </Select>
      </Form.Item>
      <Form.Item name="comment"
                 label="Коментар"
                 hasFeedback
      >
        <TextArea />
      </Form.Item>
      <Form.List name="products">
        {(fields, { add, remove }) => {
          const handleAdd = (): void => {
            add();
          };

          const handleRemove = (id: number | number[]): void => {
            remove(id);
          };

          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <WarehouseActionProductsForm key={key} name={name} restField={restField} typeField={typeField} id_warehouse={warehouseField} onRemove={handleRemove} />
              ))}
              <Form.Item>
                <Button type="dashed" onClick={handleAdd} block icon={<PlusOutlined />}>
                  Додати продукт
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Створити
          </Button>
          <Button htmlType="reset">Скинути</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
