import React, { useMemo } from 'react';
import { FileUpload } from 'src/base-component';
import { IProductImage } from 'main/interfaces';
import { UploadFile } from 'antd';

interface IProductImagesProps {
    product_id: string;
    images: IProductImage[];
    onDelete: (image_id: string) => void;
}

export const ProductImages: React.FC<IProductImagesProps> = ({ product_id, images, onDelete }) => {
    const productImages: UploadFile[] = useMemo(() => {
        return images.map((image) => ({
            uid: String(image.id),
            name: image.name,
            status: 'done',
            url: image.link,
        }));
    }, [images]);

    const handleDelete = (image_id: string): void => {
        onDelete(image_id);
    };

    return (
        <FileUpload action={'/product/' + product_id + '/image'} files={productImages} onRemove={handleDelete}/>
    );
};
