import React, { useMemo, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { ICategory, IProductCategory } from 'main/interfaces';

interface IProductCategoryFormProps {
    categories: ICategory[];
    productCategories: IProductCategory[];
    onCreate: (category_id: number) => void;
    onDelete: (category_id: number) => void;
}

export const ProductCategoryForm: React.FC<IProductCategoryFormProps> = ({ categories, productCategories, onCreate, onDelete }) => {
    const categoriesOptions = categories.map((category) => ({
        value: category.id,
        label: category?.info?.[0]
            ? category.info[0]?.value
            : ''
    }));
    const defaultValues = useMemo(() => productCategories.map((option) => Number(option.id_category)), [productCategories]);
    const [selected, setSelected] = useState<number[]>(defaultValues);

    const handleChange = (value: number[]): void => {
        selected.forEach((category) => {
            if (!value.includes(category)) {
                onDelete(category);
            }
        });
        value.forEach((category) => {
           if (!selected.includes(category)) {
               onCreate(category);
           }
        });
        setSelected(value);
    };

    return (
        <Form layout={'vertical'}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={10}>
                    <Form.Item label="Категорії товара">
                        <Select
                            mode="multiple"
                            placeholder=""
                            variant="filled"
                            value={selected}
                            onChange={handleChange}
                            style={{ flex: 1 }}
                            options={categoriesOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
