import React from 'react';
import { Popover } from 'antd';
import { OrderTTNForm } from './OrderTTNForm';
import { OrderConditions } from '../../helpers';
import { IOM_Order } from 'main/services/OrderManager';

interface IOrderTTNProps {
  children: React.ReactNode;
  order: IOM_Order;
  onChange: () => void;
}

export const OrderTTN: React.FC<IOrderTTNProps> = ({ children, order, onChange }) => {
  const handleChange = (): void => {
    onChange();
  };

  if (!OrderConditions.needToShowTTNButton(order)) return null;

  return (
    <Popover content={<OrderTTNForm order_id={order.id} onChange={handleChange}/>} trigger="click">
      {children}
    </Popover>
  );
};
