import React from 'react';
import { Col, Row, Spin } from 'antd';
import './Spinner.scss';

export const Spinner: React.FC = () => {
    return (
      <Row>
          <Col xs={24} className={'mbg-spinner'}>
              <Spin size="large" />
          </Col>
      </Row>
    );
};
