import { BaseApi } from '.';
import { ITag } from 'main/interfaces';
import { ApiBaseResponse, ApiWithPaginationResponse, BaseApiPostData, IBaseGetAllParams } from 'src/interfaces';

class TagApi extends BaseApi {
    constructor() {
        super('/tag');
    }

    async getAll(params?: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<ITag>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<ITag>>>(`?page=${params?.page ?? 1}${params?.name ? '&info=name,' + params?.name + ',LIKE' : ''}`))?.data);
    }

    async getOne(id: string): Promise<ApiBaseResponse<ITag> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ITag>>('/' + id))?.data);
    }

    async create(data: BaseApiPostData): Promise<ApiBaseResponse<ITag> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<ITag>>('/', data))?.data);
    }

    async update(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ITag> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<ITag>>('/' + id, data))?.data);
    }

    async updateInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ITag> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<ITag>>('/' + id + '/info', data))?.data);
    }

    async createInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ITag> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<ITag>>('/' + id + '/info', data))?.data);
    }
}

export const tagApi = new TagApi();
