import { IEntryPoint, IRouteItem } from 'src/interfaces';
import {
    CatalogProductAddPage,
    CatalogProductEditPage,
    CatalogProductsPage,
    CatalogCategoriesPage,
    CatalogTagPage,
    CatalogTagEditPage,
    CatalogTagAddPage,
    CatalogCategoryAddPage,
    CatalogCategoryEditPage, CatalogAttributesPage, CatalogAttributeAddPage, CatalogAttributeEditPage
} from './pages';
import { ROUTES } from 'src/constants';

const routeList: IRouteItem[] = [
    {
        path: ROUTES.CATALOG_PRODUCTS,
        exact: true,
        element: CatalogProductsPage,
    },
    {
        path: ROUTES.CATALOG_PRODUCTS_ADD,
        exact: true,
        element: CatalogProductAddPage,
    },
    {
        path: ROUTES.CATALOG_PRODUCTS_EDIT,
        exact: true,
        element: CatalogProductEditPage,
    },
    {
        path: ROUTES.CATALOG_CATEGORIES,
        exact: true,
        element: CatalogCategoriesPage,
    },
    {
        path: ROUTES.CATALOG_CATEGORIES_ADD,
        exact: true,
        element: CatalogCategoryAddPage,
    },
    {
        path: ROUTES.CATALOG_CATEGORIES_EDIT,
        exact: true,
        element: CatalogCategoryEditPage,
    },
    {
        path: ROUTES.CATALOG_TAG,
        exact: true,
        element: CatalogTagPage,
    },
    {
        path: ROUTES.CATALOG_TAG_EDIT,
        exact: true,
        element: CatalogTagEditPage,
    },
    {
        path: ROUTES.CATALOG_TAG_ADD,
        exact: true,
        element: CatalogTagAddPage,
    },
    {
        path: ROUTES.CATALOG_ATTRIBUTES,
        exact: true,
        element: CatalogAttributesPage,
    },
    {
        path: ROUTES.CATALOG_ATTRIBUTES_ADD,
        exact: true,
        element: CatalogAttributeAddPage,
    },
    {
        path: ROUTES.CATALOG_ATTRIBUTES_EDIT,
        exact: true,
        element: CatalogAttributeEditPage,
    },

];

export const CatalogApp: IEntryPoint = {
    route: routeList,
};
