import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { ProductApi, TagApi } from 'src/api';
import { IProductTag, ITag } from 'main/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { ProductTagForm } from '../';

interface IProductTagTabProps {
    product_id: string;
}

export const ProductTagTab: React.FC<IProductTagTabProps> = ({ product_id }) => {
    const [tags, setTags] = useState<ITag[] | undefined>();
    const [productTags, setProductTags] = useState<IProductTag[] | undefined>();

    const handleAddTag = useCallback(async (tag_id: number) => {
        const response = await ProductApi.addTag(Number(product_id), tag_id);
        handleChangeStatus<IProductTag>(response);
        return response;
    }, [product_id]);

    const handleDeleteTag = useCallback(async (tag_id: number) => {
        const response = await ProductApi.deleteTag(Number(product_id), tag_id);
        handleChangeStatus<IProductTag>(response);
        return response;
    }, [product_id]);

    useEffect(() => {
        if (!productTags) {
            ProductApi.getTags(product_id).then((res) => {
                setProductTags(res?.data);
            });
        }
    }, [product_id, productTags]);

    useEffect(() => {
        if (!tags) {
            TagApi.getAll().then((res) => {
                setTags(res?.data?.rows);
            });
        }
    }, [tags]);

    return productTags && tags ? (
        <>
            <Divider children={'Теги продукту'} orientation="left" orientationMargin="0"/>
            <ProductTagForm tags={tags}
                                 productTags={productTags}
                                 onCreate={handleAddTag}
                                 onDelete={handleDeleteTag}
            />
        </>
    ) : null;
};
