
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { ProductApi } from 'src/api';
import { IProduct, IProductDiscount } from 'main/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { ProductDiscountForm, ProductDiscounts } from '../';

interface IProductDiscountsTabProps {
    product_id: string;
}

export const ProductDiscountsTab: React.FC<IProductDiscountsTabProps> = ({ product_id }) => {
    const [product, setProduct] = useState<IProduct | undefined>();
    const [productDiscounts, setProductDiscounts] = useState<IProductDiscount[] | undefined>();

    const getProductDiscounts = useCallback(() => {
        ProductApi.getDiscounts(product_id).then((res) => {
            setProductDiscounts(res?.data ?? []);
        });
    }, [product_id]);

    const handleAddDiscount = useCallback(async (discount: number) => {
        const response = await ProductApi.addDiscount(Number(product_id), discount);
        handleChangeStatus<IProductDiscount>(response);
        getProductDiscounts();
        return response;
    }, [product_id, getProductDiscounts]);

    const handleDeleteDiscount = useCallback(async () => {
        const response = await ProductApi.deleteDiscount(Number(product_id));
        handleChangeStatus<IProductDiscount>(response);
        getProductDiscounts();
        return response;
    }, [product_id, getProductDiscounts]);

    useEffect(() => {
        if (!product) {
            ProductApi.getOne(product_id).then((res) => {
                setProduct(res?.data);
            });
        }
    }, [product_id, product]);

    useEffect(() => {
        if (!productDiscounts) {
            getProductDiscounts();
        }
    }, [productDiscounts, getProductDiscounts]);

    return product && productDiscounts ? (
        <Row gutter={31}>
            <Col xs={24} sm={24} md={24} lg={9}>
                <Divider children={'Додати знижки до продукту'} orientation="left" orientationMargin="0"/>
                <ProductDiscountForm onCreate={handleAddDiscount}/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={9}>
                <Divider children={'Список доданих знижок'} orientation="left" orientationMargin="0"/>
                <ProductDiscounts discounts={productDiscounts} onDelete={handleDeleteDiscount} product={product} />
            </Col>
        </Row>
    ) : null;
};
