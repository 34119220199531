import React from 'react';
import {
  Button,
  Form,
  Input,
  Space,
} from 'antd';
import { CustomerApi } from 'src/api';
import { handleChangeStatus } from 'src/helpers';
import { ICustomer } from 'main/interfaces';

export const CustomerAdd: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleFinish = (values: Record<string, string | boolean | number>): void => {
    setLoading(true);
    CustomerApi.create(values).then((res) => {
      handleChangeStatus<ICustomer>(res);
      if (res?.status) form.resetFields();
      setLoading(false);
    });
  };

  return (
    <Form
      name="validate_other"
      onFinish={handleFinish}
      form={form}
      labelCol={{ span: 6 }}
      disabled={loading}
      wrapperCol={{ span: 14 }}
      initialValues={{
        show_menu: false,
      }}
      style={{ maxWidth: 900 }}
    >
      <Form.Item name={'name'}
                 label="Імʼя"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item name={'surname'}
                 label="Прізвище"
                 rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                 hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item name={'middle_name'}
                 label="По батькові"
                 hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item name={'email'}
                 label="Email"
                 rules={[
                   { required: true, message: 'Поле повинно бути заповнене' },
                   { required: true, type: 'email', message: 'Введіть коректний email', }
                 ]}
                 hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item name={'phone'}
                 label="Телефон"
                 hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item name={'password'}
                 label="Пароль"
                 hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Space>
          <Button type="primary" htmlType="submit">
            Створити
          </Button>
          <Button htmlType="reset">Скинути</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
