import React, { ReactElement, ReactNode } from 'react';
import { Table as AntTable, TablePaginationConfig, TableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';

interface ITableProps<T> {
    data: T[];
    columns: TableProps<T>['columns'];
    loading: boolean;
    pagination?: TablePaginationConfig;
    onExpandable?: (record: T) => ReactNode;
}

export const Table = <T, >({ data, columns, loading, pagination, onExpandable }: ITableProps<T>): ReactElement<ITableProps<T>> => {
    return (
        <div className={'mb-table'}>
            <AntTable columns={columns as AnyObject[]}
                      dataSource={data as AnyObject[]}
                      loading={loading}
                      pagination={pagination}
                      expandable={{
                          expandedRowRender: onExpandable,
                          rowExpandable: (record) => !!onExpandable,
                      }}
            />
        </div>
    );
};
