import React from 'react';
import { ORDER_STATUSES } from 'src/interfaces';
import { Tag } from 'antd';

interface IOrderStatusProps {
    id: number;
    name: string;
}

export const OrderStatus: React.FC<IOrderStatusProps> = ({ id, name }) => {
    switch (id) {
        case ORDER_STATUSES.NEW:
            return <Tag color="green">{name}</Tag>;
        case ORDER_STATUSES.AWAITING_PAYMENT:
            return <Tag color="cyan">{name}</Tag>;
        case ORDER_STATUSES.PAID:
            return <Tag color="blue">{name}</Tag>;
        case ORDER_STATUSES.COMPLETED:
            return <Tag color="gold">{name}</Tag>;
        case ORDER_STATUSES.IN_THE_WAY:
            return <Tag color="purple">{name}</Tag>;
        case ORDER_STATUSES.FINISHED:
            return <Tag color="geekblue">{name}</Tag>;
        case ORDER_STATUSES.WAITING:
        case ORDER_STATUSES.CANCELED:
        case ORDER_STATUSES.RETURNED:
        case ORDER_STATUSES.COMPLAINT:
        case ORDER_STATUSES.RETURN_TO_THE_WAREHOUSE:
            return <Tag color="#f50">{name}</Tag>;
        default:
            return <Tag>{name}</Tag>;
    }
};
