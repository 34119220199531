import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, Space, Typography } from 'antd';
import { CustomerApi, DirectoriesApi } from 'src/api';
import { ICustomer, ICustomerCompany, ICustomerPartnerInfo, ISpecialty } from 'main/interfaces';
import { CustomerEditMain } from './CustomerEditMain';
import { ApiBaseResponse, BaseApiPostData } from 'src/interfaces';
import { handleChangeStatus } from 'src/helpers';
import { CustomerEditPartner } from './CustomerEditPartner';
import { CustomerEditCompany } from './CustomerEditCompany';
import {
  CustomerOrders,
  CustomerPartnerOrders,
  CustomerRegisterCompany,
  CustomerRegisterPartner
} from '../';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { WarehouseLots } from 'src/apps/warehouse/components';

const Panel = Collapse.Panel;

interface ICustomerEditProps {
  customer_id: string;
}

export const CustomerEdit: React.FC<ICustomerEditProps> = ({ customer_id }) => {
  const [customer, setCustomer] = useState<ICustomer>();
  const [specialities, setSpecialities] = useState<ISpecialty[]>();
  const [loading, setLoading] = useState(true);

  const getCustomer = useCallback((): void => {
    setLoading(true);
    CustomerApi.getOne(customer_id).then((res) => {
      setCustomer(res?.data);
      setLoading(false);
    });
  }, [customer_id]);

  const handleChangeCustomer = useCallback((values: BaseApiPostData): void => {
    setLoading(true);
    CustomerApi.update(Number(customer_id), values).then((res) => {
      handleChangeStatus<ICustomer>(res);
      getCustomer();
      setLoading(false);
    });
  }, [customer_id, getCustomer]);

  const handleRegistrationPartner = useCallback(async (values: BaseApiPostData): Promise<ApiBaseResponse<ICustomerPartnerInfo> | undefined> => {
    setLoading(true);
    const res = await CustomerApi.registrationPartner(Number(customer_id), values);
    handleChangeStatus<ICustomerPartnerInfo>(res);
    getCustomer();
    setLoading(false);
    return res;
  }, [customer_id, getCustomer]);

  const handleRegistrationCompany = useCallback(async (values: BaseApiPostData): Promise<ApiBaseResponse<ICustomerCompany> | undefined> => {
    setLoading(true);
    const res = await CustomerApi.registrationCompany(Number(customer_id), values);
    handleChangeStatus<ICustomerCompany>(res);
    getCustomer();
    setLoading(false);
    return res;
  }, [customer_id, getCustomer]);

  useEffect(() => {
    if (!customer) {
      getCustomer();
    }
  }, [customer, getCustomer]);

  useEffect(() => {
    if (!specialities) {
      setLoading(true);
      DirectoriesApi.getSpecialty().then((res) => {
        setSpecialities(res?.data ?? []);
        setLoading(false);
      });
    }
  }, [specialities]);

  if (!customer || !specialities) return null;

  return (
    <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
      <Collapse defaultActiveKey={['1']} destroyInactivePanel accordion>
        <Panel header="Основні" key="1">
          <CustomerEditMain customer={customer} onChange={handleChangeCustomer} loading={loading} />
        </Panel>
        {customer?.partner_info
          ? (
              <Panel header="Дані партнера" key="2">
                <CustomerEditPartner partner_info={customer.partner_info} onChange={handleChangeCustomer} specialities={specialities} loading={loading} />
              </Panel>
            )
          : (
              <Panel header={<Space><PlusCircleTwoTone/>Реєстрація партнера</Space>} key="2">
                <CustomerRegisterPartner specialities={specialities} onSuccess={handleRegistrationPartner} />
              </Panel>
            )
        }
        {customer?.company
          ? (
              <Panel header="Дані про компанію" key="3">
                <CustomerEditCompany company_info={customer.company} onChange={handleChangeCustomer} loading={loading} />
              </Panel>
            )
          : (
              <Panel header={<Space><PlusCircleTwoTone/>Реєстрація компанії</Space>} key="3">
                <CustomerRegisterCompany onSuccess={handleRegistrationCompany} />
              </Panel>
            )
        }
      </Collapse>
      <Typography.Title level={4} type={'secondary'}>Замовлення</Typography.Title>
      <CustomerOrders customer_id={customer_id} />
      {customer.partner && (
        <>
          <Typography.Title level={4} type={'secondary'}>Партнерські замовлення</Typography.Title>
          <CustomerPartnerOrders customer_id={customer_id}/>
          {customer?.warehouse?.id_warehouse && (
            <>
              <Typography.Title level={4} type={'secondary'}>Товари клієнта</Typography.Title>
              <WarehouseLots warehouse_id={String(customer?.warehouse?.id_warehouse)} />
            </>
          )}
        </>
      )}
    </Space>
  );
};
