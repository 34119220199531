import { useCallback, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { ApiBaseResponse, ApiWithPaginationResponse, IBaseGetAllParams } from 'src/interfaces';

interface IUsePagination<T> {
    pagination: TablePaginationConfig | undefined;
    handlePagination: (
      res: ApiBaseResponse<ApiWithPaginationResponse<T>> | undefined,
      getData: (params: IBaseGetAllParams) => void,
      page?: number,
      pageSize?: number
    ) => void
}

export const usePagination = <T>(): IUsePagination<T> => {
    const [pagination, setPagination] = useState<TablePaginationConfig | undefined>();

    const handlePagination = useCallback((
      res: ApiBaseResponse<ApiWithPaginationResponse<T>> | undefined,
      getData: (params: IBaseGetAllParams) => void,
      page?: number,
      pageSize?: number
    ): void => {
        setPagination({
            pageSize: pageSize ?? res?.data?.limit,
            total: res?.data?.count,
            current: page ?? res?.data?.page,
            onChange: (page: number, pageSize: number): void => {
                getData({ page, pageSize });
            },
        });
    }, []);

    return { pagination, handlePagination };
};
