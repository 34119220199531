import React, { useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Space,
} from 'antd';
import { CATEGORY_FIELDS } from 'src/interfaces';
import { TagApi } from 'src/api';
import { handleChangeStatus, makeTransliteration } from 'src/helpers';
import { ITag } from 'main/interfaces';

export const TagAddForm: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);

    const nameField = Form.useWatch('name', form);

    const handleFinish = (values: Record<string, string | boolean | number>): void => {
        setLoading(true);
        TagApi.create(values).then((res) => {
            handleChangeStatus<ITag>(res);
            if (res?.status) form.resetFields();
            setLoading(false);
        });
    };

    useEffect(() => {
        form.setFieldValue('alias', makeTransliteration(nameField));
    }, [form, nameField]);

    return (
        <Form
            name="validate_other"
            onFinish={handleFinish}
            form={form}
            labelCol={{ span: 6 }}
            disabled={loading}
            wrapperCol={{ span: 14 }}
            initialValues={{
                show_menu: false,
            }}
            style={{ maxWidth: 900 }}
        >
            <Form.Item name={CATEGORY_FIELDS.NAME}
                       label="Назва"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item name={CATEGORY_FIELDS.ALIAS}
                       label="Аліас"
                       rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                       hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Створити
                    </Button>
                    <Button htmlType="reset">Скинути</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
