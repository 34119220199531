import React, { ReactNode, useMemo } from 'react';
import { Space, TableProps, Typography } from 'antd';
import { Formatter, sortByDate } from 'src/helpers';
import { Table } from 'src/base-component';
import { IOM_OrderWarehouseAction } from 'main/services/OrderManager';
import { OrderWarehouseActionsProducts } from './OrderWarehouseActionsProducts';

const { Title } = Typography;

interface IOrderWarehouseActionsProps {
  warehouse_actions: IOM_OrderWarehouseAction[];
}

export const OrderWarehouseActions: React.FC<IOrderWarehouseActionsProps> = ({ warehouse_actions }) => {
  const warehouseActionsByDate = useMemo(() => {
    return warehouse_actions.sort(sortByDate<IOM_OrderWarehouseAction>('createdAt', 'DESC'));
  }, [warehouse_actions]);

  const columns: TableProps<IOM_OrderWarehouseAction>['columns'] = [
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Менеджер',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Коментар',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => Formatter.toDate(record?.createdAt ?? ''),
    },
  ];

  const handleExpandable = (record: IOM_OrderWarehouseAction): ReactNode => {
    return <OrderWarehouseActionsProducts action_products={record.action_products}/>;
  };

  return (
    <>
      <Title level={5}>Рух товарів на складах</Title>
      <Space/>
      <Table columns={columns}
             data={warehouseActionsByDate}
             loading={false}
             onExpandable={handleExpandable}
      />
    </>
  );
};
