import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { IStatus } from 'main/interfaces';
import { DirectoriesApi, OrderApi } from 'src/api';
import { handleChangeStatus } from 'src/helpers';
import { OrderStatus } from '../';

interface IOrderStatusFormProps {
    order_id: number;
    allowed_statuses: number[];
    onChange?: () => void;
}

const { TextArea } = Input;
const { Option } = Select;

export const OrderStatusForm: React.FC<IOrderStatusFormProps> = ({ order_id, allowed_statuses, onChange }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [statuses, setStatuses] = useState<IStatus[] | undefined>();

    const filteredStatuses = statuses?.filter((item: IStatus) => allowed_statuses.includes(item.id));

    const handleFinish = (values: Record<string, string | boolean | number>): void => {
        setLoading(true);
        OrderApi.updateStatus(order_id, values).then((res) => {
            handleChangeStatus<Record<string, string>>(res);
            if (res?.status) form.resetFields();
            onChange && onChange();
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!statuses) {
            DirectoriesApi.getStatuses().then((res) => {
                setStatuses(res?.data);
            });
        }
    }, [statuses]);

    return (
        <Form
            name="order_status_form"
            onFinish={handleFinish}
            form={form}
            layout={'vertical'}
            disabled={loading}
            style={{ maxWidth: 900 }}
        >
            <Form.Item
                name={'id_status'}
                label="Новий статус:"
                hasFeedback
                rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
            >
                <Select placeholder="Оберіть варіант із списку">
                    {filteredStatuses?.map((status) => (
                        <Option value={status.id} key={status.id}>
                            <OrderStatus id={status.id} name={status?.info?.[0] ? status.info[0]?.value : ''}/>
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name={'comment'}
                       label="Коментар:"
                       hasFeedback
            >
                <TextArea />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                    Змінити
                </Button>
            </Form.Item>
        </Form>
    );
};
