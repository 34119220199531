import React from 'react';
import { IAttributeValue, IAttributeValueInfo } from 'main/interfaces';
import { ApiBaseResponse, FormChanges } from 'src/interfaces';
import { Button, Collapse, List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { AttributeItemInfoForm } from '../';

interface IAttributeItemsProps {
    items: IAttributeValue[];
    onDelete: (product_attribute_id: number) => Promise<ApiBaseResponse<IAttributeValue> | undefined>
    onUpdate: (attribute_value_id: number, changedValues: FormChanges, language: number) => Promise<ApiBaseResponse<IAttributeValueInfo> | undefined>
    onCreate: (attribute_value_id: number, changedValues: FormChanges, language: number) => Promise<ApiBaseResponse<IAttributeValueInfo> | undefined>
}

export const AttributeItems: React.FC<IAttributeItemsProps> = ({ items, onDelete, onCreate, onUpdate }) => {
    const handleDelete = (product_attribute_id: number): void => {
        onDelete(product_attribute_id);
    };

    const handleCreateInfo = (attribute_value_id: number, changedValues: FormChanges, language: number): void => {
        onCreate(attribute_value_id, changedValues, language);
    };

    const handleUpdateInfo = (attribute_value_id: number, changedValues: FormChanges, language: number): void => {
        onUpdate(attribute_value_id, changedValues, language);
    };

    return (
        <List
            bordered
            dataSource={items}
            renderItem={(item) => {
                const actions = [
                    <Button onClick={() => handleDelete(item.id)} key={item.id}>
                        <DeleteOutlined/>
                    </Button>
                ];
                const info = item?.info ? item.info[0] : null;
                const collapseItem = [{
                    key: item.id,
                    label: info?.value,
                    children: <AttributeItemInfoForm item={item} onUpdate={handleUpdateInfo} onCreate={handleCreateInfo}/>,
                }];
                return (
                    <List.Item actions={actions} style={{ display: 'flex' }} key={item.id}>
                        <Collapse items={collapseItem} bordered={false} ghost style={{ width: '100%' }}/>
                    </List.Item>
                );
            }}
        />
    );
};
