import React from 'react';
import { IProduct, IProductDiscount } from 'main/interfaces';
import { Button, List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IProductDiscountsProps {
    product: IProduct;
    discounts: IProductDiscount[];
    onDelete: () => void;
}

export const ProductDiscounts: React.FC<IProductDiscountsProps> = ({ product, discounts, onDelete }) => {
    const handleDelete = (): void => {
        onDelete();
    };

    return (
        <List
            bordered
            dataSource={discounts}
            renderItem={(item) => {
                const actions = [
                    <Button onClick={() => handleDelete()} key={item.id}>
                        <DeleteOutlined/>
                    </Button>
                ];
                return (
                    <List.Item actions={actions} style={{ display: 'flex' }} key={item.id}>
                        <b>{item.discount}€ ({(100 - (item.discount / product.price) * 100).toFixed(0)}%)</b>
                    </List.Item>
                );
            }}
        />
    );
};
