import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'src/base-component';
import { Button, Space, TableProps } from 'antd';
import {
  DeleteFilled,
} from '@ant-design/icons';
import { ProductApi, SettingApi } from 'src/api';
import { ApiBaseResponse, BaseApiPostData, IBaseGetAllParams } from 'src/interfaces';
import { IProduct, IProductRelated } from 'main/interfaces';
import { RelatedProductsGridEditSort } from './RelatedProductsGridEditSort';
import { handleChangeStatus } from 'src/helpers';
import { RelatedProductsPush } from '../';

export const RelatedProductsGrid: React.FC = () => {
  const [data, setData] = useState<IProductRelated[]>();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<IProduct[] | undefined>();

  const getRelatedProducts = useCallback((params: IBaseGetAllParams): void => {
    setLoading(true);
    SettingApi.getRelatedProducts(params).then((res) => {
      setData(res?.data ?? []);
      setLoading(false);
    });
  }, []);

  const getProducts = useCallback((value?: string) => {
    ProductApi.getAll({ page: 1, name: value }).then((res) => {
      setProducts(res?.data?.rows);
    });
  }, []);

  const handlePush = useCallback(async (values: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
    setLoading(true);
    const res = await SettingApi.pushRelatedProduct(values);
    handleChangeStatus<Record<string, string>>(res);
    getRelatedProducts({});
    setLoading(false);
    return res;
  }, [getRelatedProducts]);

  const handleChange = (id: number): (related_product: BaseApiPostData) => void => {
    return (related_product: BaseApiPostData): void => {
      setLoading(true);
      SettingApi.updateRelatedProducts(id, related_product).then((res) => {
        handleChangeStatus<Record<string, string>>(res);
        getRelatedProducts({});
      });
    };
  };

  const handleSearchProduct = useCallback((value: string): void => {
    getProducts(value);
  }, [getProducts]);

  const handleDelete = (id: number): () => void => {
    return () => {
      setLoading(true);
      SettingApi.deleteRelatedProduct(id).then((res) => {
        handleChangeStatus<Record<string, string>>(res);
        getRelatedProducts({});
      });
    };
  };

  const columns: TableProps<IProductRelated>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Продукт',
      dataIndex: 'id_product',
      key: 'id_product',
      render: (_, record) => record?.product?.info?.[0] ? record?.product?.info?.[0]?.name ?? '' : ''
    },
    {
      title: 'Сортування',
      dataIndex: 'sort',
      key: 'sort',
      render: (_, record) => (
        <RelatedProductsGridEditSort related_product={record} onChange={handleChange(record.id)} />
      )
    },
    {
      title: 'Дії',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={handleDelete(record.id)}>
            <DeleteFilled/>
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!data) {
      getRelatedProducts({});
    }
  }, [getRelatedProducts, data]);

  useEffect(() => {
    if (!products) {
      getProducts();
    }
  }, [products, getProducts]);

  return (
    <>
      <Table columns={columns} data={data ?? []} loading={loading} />
      <RelatedProductsPush products={products ?? []} onSearch={handleSearchProduct} onAdd={handlePush}/>
    </>
  );
};
