import { BaseApi } from '.';
import { ApiBaseResponse, ApiWithPaginationResponse, IBaseGetAllParams } from 'src/interfaces';
import { IUser } from 'main/interfaces';

class UserApi extends BaseApi {
  constructor() {
    super('/user');
  }

  async getAll(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<IUser>> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<IUser>>>(`?page=${params?.page ?? 1}${params?.name ? '&info=name,' + params.name + ',LIKE' : ''}`))?.data);
  }
}

export const userApi = new UserApi();
